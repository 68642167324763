import React, {useState, useEffect} from "react";

const ContactForm = ({onStepChange, setName, setEmail, initialEmail}) => {

    // Set intial email  to what is stored in Redux store
    const [localEmail, setLocalEmail] = useState(initialEmail || '');
    const [isEmailValid, setEmailValid] = useState(true);
    const [isNameValid, setNameValid] = useState(true);

    useEffect(() => {
        if (initialEmail) {
            setLocalEmail(initialEmail);
            validateEmail(initialEmail);
        }
        }, [initialEmail]);

    // Email validation function
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setLocalEmail(newEmail);
        setEmailValid(validateEmail(newEmail));
        setEmail(newEmail); // Update the parent component's email state
    };

    // Name validation fubction
    const validateName = (fullname) => {
        const namePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
        return namePattern.test(fullname)
    }

    const handleNameChange = (e) => {
        const newFullName = e.target.value;
        setNameValid(validateName(newFullName))
        setName(newFullName)
    }

    const handleSubmit = async (event)=>{
        event.preventDefault();

        // Validation logic
        const nameValue = event.target.name.value;

        const emailValue = localEmail;

        if (nameValue.trim() === '') {
            setNameValid(false);
        }
        if (emailValue.trim() === '' || !validateEmail(emailValue)) {
            setEmailValid(false);
        }

        // Continue with form submission if all inputs are valid
        if (nameValue.trim() !== '' && emailValue.trim() !== '' && validateEmail(emailValue)) {
            onStepChange();
            setName(event.target.name.value)
            setEmail(event.target.email.value)

            // Add customer name to dataLayer
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'gtm.click',  // Keep same event name as initial push
                'customerData': {             // Keep same property name as initial push
                    'name': event.target.name.value
                }
            });
        }

    }

    return(
    <div className="space-y-4 mt-8 ">
        <div className='border p-5 bg-gray-100'>
            <form onSubmit={handleSubmit} noValidate>
            <div >
                {/* <label className="block text-md font-bold text-gray-700 mb-1">
                Full Name*
                </label> */}
                <div className="relative">
                    <input
                        type="text"
                        name="name"
                        id="name"
                       
                        placeholder="Full name *"
                        aria-invalid="true"
                        aria-describedby="email-error"
                        className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500
                            placeholder:text-lg
                            ${isNameValid ? '' : 'ring-red-500 bg-red-200'}`}
                        // className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                        onChange={handleNameChange}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    </div>
                </div>
            </div>
            
            <div className="pt-5">
                <div>
                {/* <label className="block text-md font-bold text-gray-700 mb-1">
                    Email*
                </label> */}
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={localEmail}
                    className={`w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-500 focus:border-gray-500
                        placeholder:text-lg
                        ${isEmailValid ? '' : 'ring-red-500 bg-red-200'}`}
                    // className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Email *"
                    aria-invalid="true"
                    aria-describedby="email-error"
                    onChange={handleEmailChange}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                </div>
            </div>
            { !isEmailValid && 
            <p className="mt-2 text-md font-medium text-red-600" id="email-error">Email is required.</p> }

            </div>
                <button
                id="submit"
                className="block mx-auto w-5/6 bg-green-600 text-white py-6 rounded-lg font-bold hover:bg-green-600 transition-colors mt-6"
                >
                <span className="text-2xl lg:text-3xl">Claim Special Offer!</span>
                <h3 className="uppercase mt-3 font-medium text-md">Yes! I Want This Free Book Now!</h3>
            </button>
            </form>
        </div>
    </div>
    )

}

export default ContactForm