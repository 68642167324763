import React from 'react';

const CheckoutHeader = ({ currentStep }) => {

  const steps = [
    {
      number: 1,
      title: "Step 1:",
      subtitle: "Shipping Info",
      state: currentStep === 1 ? "active" : currentStep > 1 ? "completed" : "inactive"
    },
    {
      number: 2,
      title: "Step 2:",
      subtitle: "Payment Info",
      state: currentStep === 2 ? "active" : currentStep > 2 ? "completed" : "inactive"
    },
  ];

  return (
    <div className="w-full">
      <div className="flex flex-row items-center">
      <div className="w-full">
        <div className="flex">
          {steps.map((step, index) => (
            <div key={step.number} className="flex flex-1 items-center">
              {/* Step Marker */}
              <div className={`w-8 h-8 rounded-full flex items-center justify-center
                ${step.state === 'active' ? 'bg-gray-600 text-white' : 
                  step.state === 'completed' ? 'bg-green-600 text-white' : 
                  'bg-gray-200 text-gray-600'}`}>
                <span className="text-xl font-medium">{step.number}</span>
              </div>

              {/* Step Text */}
              <div className="ml-4 flex flex-col">
                <span className={`text-xl font-medium
                  ${step.state === 'active' ? 'text-gray-600' : 
                    step.state === 'completed' ? 'text-green-700' : 
                    'text-gray-600'}`}>
                  {step.title}
                </span>
                <span className="text-lg text-gray-500">
                  {step.subtitle}
                </span>
              </div>

              {/* Connector Line */}
              <div className="flex-1 mx-4">
                <div className={`h-1 ${
                  step.state === 'completed' ? 'bg-green-500' : 
                  step.state === 'active' ? 'bg-gray-500' :
                  'hidden'
                }`}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
    </div>
  );
};

export default CheckoutHeader;