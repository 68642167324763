import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CheckoutHeader from '../upsell/checkout/checkoutHeader';
import PaymentFormWraper from '../upsell/checkout/v1PaymentFormWraper';

import { IoCloseCircle } from "react-icons/io5";
import ContactForm from '../../newsletter/upsell/checkout/v1ContactForm';




const OrderModal = ({ isOpen, onClose }) => {

  // Check for optin data in Redux Store and use optin email 
  const optinData = useSelector( state => state.optin.data);
  const storedEmail = optinData?.newOptinRecord?.email || null;

  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(storedEmail || null);

  const handleStepChange = () => {
    setCurrentStep(currentStep + 1)
  }
  
  useEffect(() => {
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, [storedEmail]);

  if (!isOpen) return null;
  
  return (
    <div className="w-full fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50">
      <div className="min-h-screen px-0 py-6 flex items-center justify-center">
        <div className="w-full h-screen max-h-[90%] max-w-6xl bg-white rounded-xl relative my-2">
        {/* Close button */}
        <div className="absolute top-0 right-0 h-16 w-16 z-10">
          <button 
            onClick={onClose}
            className='bg-white rounded-full border border-white text-gray-900 hover:text-gray-950 -my-10 mx-7'
            // className="absolute right-0 top-0 text-gray-500 hover:text-gray-700"
          >
            <IoCloseCircle className='h-10 w-10' />
          </button>
        </div>

      {/* Scrollable content container */}
      <div className="h-full overflow-y-auto px-6 py-4">
        <CheckoutHeader currentStep={currentStep}/>
        {/* Checkout Forms */}
        {
          currentStep === 1 ? 
          <ContactForm 
            step={currentStep}
            onStepChange={handleStepChange}
            setName={setName}
            setEmail={setEmail}
            initialEmail={email}
          /> : 
          <PaymentFormWraper 
              // bump={bump}
              name={name}
              email={email}
              // price={total(bump).toFixed(2)}
              // onBumpChange={handleBumpChange}
              onStepChange={handleStepChange}
          />
        }
          

        </div>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;