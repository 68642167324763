import EatToburnGuide from '../../../assets/img/product/EatToBurnGuide72_300.png'

export default function BookDisplayBottom(){
    return(
        <div className="
     
          mt-10 mb-4 lg:mb-10 border-2 
          bg-purple-800

          pb-8 l
          g:relative 
          lg:z-10 
          lg:pb-0">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
              <div className="relative lg:-my-8">
                <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
                <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:h-full lg:p-0">
                  <img
                    alt=""
                    src={EatToburnGuide}
                    //frontend/src/assets/img/product/EatToBurnGuide72_300.png
                    // className="relative aspect-[10/6] w-full rounded-xl object-cover shadow-xl sm:aspect-[16/7] lg:aspect-auto lg:h-full"
                    className="relative w-full rounded-xl shadow-2xl border border-gray-400 object-contain h-auto lg:h-full"
                  />
                </div>
              </div>
              <div className="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
                <div className="mx-auto max-w-md sm:max-w-2xl lg:max-w-none lg:px-0 lg:py-20">
                  <blockquote>
                    <div className='text-center'>
                      <p className="mt-6 capitalize text-xl sm:text-4xl font-medium text-yellow-200">Every last one of my miracle tricks for <span className='font-black'>losing weight fast</span> is inside this<span className='font-black'> Easy to read book</span>.</p>
                      <p className="mt-6 text-xl sm:text-4xl font-medium text-gray-50">
                      Right Now You Can Get This Book
                      <br/>
                      <span className='underline '>FOR FREE!</span>
                        {/* <span className='font-bold'>FREE Book </span>(<u className='underline decoration-gray-700 '>$37 Value</u>)<br/> Just For Saying 'Maybe'! */}
                      </p>
                      {/* <p className="mt-6  text-xl sm:text-3xl font-medium text-gray-800">
                     Here are just a few of the remarkable keto diet shortcuts you will find in this book:
                      </p> */}
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
    )
}