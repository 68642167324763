// https://www.nobsletter.com/special-offer-1 use bullets as templates
import { useState, useCallback } from 'react';
import KetoWins from './v1KetoWinsSection'
import ContentBullets from './v1BulletsSection'
import HighlightHeader from './v1HighlightHeader'
// import CheckoutForm from './v1CheckoutForm'
import BottomCTA from './v1BottomCTA';
import ActionButton from './v1ActionButton'
import BookDisplay from './v1BookDisplay'
import LetterStarter from './v1LetterStarter'
import BookDisplayBottom from './v1BookDisplayBottom'
import BottomBanner from './v1MobileBottomBanner'
import ProgramHighlight from './v1ProgramHighlight'
import Footer from './v1Footer'
import MemberBenefits from './v1MemberBenefits'
import OrderModal from './v1OrderModal'

export default function ContentSection01() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  // Use useCallback to ensure the function reference stays stable
  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, []); // Empty dependency array since setIsModalOpen is stable

  return (
    <div className="bg-orange-50 px-2 py-2 lg:px-8 ">
      <div className="bg-white px-0 pt-12 mx-auto max-w-[98%] sm:max-w-[60%] text-gray-700 border-violet-200 border shadow-2xl rounded-3xl -mt-24">
        {/* <p className="text-base/7 font-semibold text-indigo-600">Introducing</p> */}
        {/* <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          JavaScript for beginners
        </h1> */}
        <BookDisplay />
        <p className='my-6 sm:my-20'>{' '}</p>
        <ActionButton onOpenModal={handleModalOpen}/>
        <LetterStarter />
        <div className='max-w-[90%] mx-auto text-lg/7 lg:text-[22px]/9'>
        <h2 className="mt-20 text-center text-2xl/7 font-semibold lg:text-3xl">
            If You Want To Lose Weight … Look Years Younger <br/> And Enjoy ‘Boodles’ Of Energy The Whole Day… <br/>
            Then My Story Will Be The Most Exciting Message You’ll Ever Read!
        </h2>  
        {/* <h2 className='my-12 text-3xl'>If you want to lose weight … look years younger and enjoy ‘Boodles’ of energy the whole day… </h2> */}
        {/* <h3 className="mt-5 font-semibold text-3xl/8 text-center">then my story will be the most exciting message you’ll ever read!</h3> */}
        <h4 className="mt-5 text-2xl sm:text-3xl">Here is why:</h4>
        
          


          {/* <div className="flex flex-col mt-5">
            <p className="text-xl/8">
              If you dream about strolling into a clothing store and buying regular-size clothes off the "ON SALE" rack…
            </p>
            <p className="font-semibold text-xl/8 mt-2 sm:mt-0">
              (Being a girl in a clothing store is hard. Being heavy can sometimes feel devastating…)
            </p>
          </div> */}
          {/* <p className="mt-5 text-xl/8">
            If this feels like you… then my story will be the most exciting message you’ll ever read!
          </p>
          <h4 className="mt-5 font-semibold text-xl/8">Here is why:</h4> */}
          <p className="mt-5 ">
            My husband has been a member of this eye-crossingly boring club where they talk about electronics and stuff, for nearly six years.
          </p>
          <p className="mt-5 ">
            On occasion, I accompany him when they are doing something halfway interesting. It happens often enough so that I know most of his club buddies at least a little bit.        
          </p>
          <p className="mt-5 ">
            They hadn’t seen me since the last summer and some hadn’t seen me since the summer before (when I was almost 298 lbs)
          </p>
          <p className="mt-5 ">
            At the last monthly breakfast social, my conversations with several guys were oddly stilted and felt a bit uncomfortable, and I couldn’t figure out what was the reason. 
          </p>
          <p className="mt-5">
          Yesterday I found out exactly why….
          </p>
          <p className="mt-5 ">
            They had another event this weekend, and two of them cornered my husband and wanted to know what in the world had happened to his marriage… 
          </p>
          <p className=" mt-5 font-semibold ">
            <span className='bg-yellow-100'>…and where did he meet his new and <i>“sooo much younger-looking”</i> girlfriend?</span>
          </p>
          <p className="mt-5 ">
            At first, my husband thought they were teasing, but then he realized they actually believed the woman with him at the 
            breakfast a week ago <u className='italic'>was not the same person</u> as me (his wife). 
          </p>
          <p className="mt-5 ">
            He had to show several of my progress pictures (on his cell phone) to get them to believe it was still me!
          </p>
          <p className="mt-5 ">
          And this is only the beginning… 
          </p>
        </div>
        <h2 className="my-16 font-semibold text-2xl/7 lg:text-3xl text-center">
        I have gifted myself with 
        literally a whole <u>new life</u>!
        </h2>
        {/* <h2 className="my-16 font-semibold text-2xl/8 text-center">
        I have gifted myself with <br></br>
        literally a whole <u>new life</u>!
        </h2> */}
        <div className='max-w-[90%] mx-auto text-lg/7 lg:text-[22px]/9'>
        <p className="mt-5 ">
          It felt like my biggest victory when I was finally able to browse through the ON SALE rack in Dillard’s 
          and<br></br> <u>try on things I loved</u>…

        </p>
        <p className="mt-5 font-semibold ">
          <span className=' bg-yellow-100'>…it is a unique feeling to walk into almost any store and be able to find clothing now!</span>
        </p>
        <p className="mt-5 ">
          I feel so much more energy, I want to go places and do things I've always dreamed of… 
          (<b className='underline decoration-2 decoration-indigo-700'>It’s almost hard to believe how much energy I have</b>). </p>
        <p className="mt-5 ">
          I don’t need to nap every mid-afternoon 
          and now <b className='underline decoration-2 decoration-indigo-700'>my focus is “laser” sharp</b>. 
        </p>
        <p className="mt-5">
          My blood pressure is perfect, my heartburn is gone, and falling asleep at night is easier 
          (<span className='bg-yellow-100'>when I wake up the next morning, I’m actually awake</span>)… 
        </p>
        <p className="mt-5 ">
          Believe it or not I still want to pinch myself every time I look in the mirror…
        </p>
        <p className="mt-5 ">
          It is hard to believe… that just a few months ago (I’ve counted 345 days…) my life was split into “Me Before” life and my new “Me Today” life.
        </p>
        <p className="mt-5 ">
          The me “Before” had grown accustomed to what I saw as "failure" and was ashamed of how far I had let herself go. 
          Binge-eating was my only comfort, and life's struggles had given me no motivation to stop.
        </p>
        <p className="mt-5 ">
          My rock bottom was going to the doctor because my heart was palpitating and the doctor is telling me I was pre-diabetic and that he was putting me on pills. 
        </p>
        <p className="mt-5">
          I absolutely <u className='font-semibold'>refused</u> to let this be my life…
        </p>
        <h4 className="mt-5 font-semibold">
          There had to be a better way… 
        </h4>
        <p className="mt-5">
          Why is it always pills and more pills…?!?!  
        </p>
        <p className="mt-5 ">
         My gut feeling was, there has to be just as <b className='bg-yellow-100'>powerful and much safer natural alternative</b> !!!  
        </p> 
        <p className="mt-5 ">
          I was hell-bent on finding it…
        </p> 
        <p className='mt-5'>
          That's when I have discovered the Ketogenic Diet and the amazing successes shared by almost everyone who tried it!!!
        </p>
        <p className='mt-5 font-semibold'>
          But this was not the case for me...
        </p>
        <p className="mt-5 ">
          After trying and failing at keto 6 times, each time I have discovered a couple of new <b className='underline decoration-2 decoration-indigo-700'>tricks</b>,  
          one or two <b className='underline decoration-2 decoration-indigo-700'>twists</b> or <b className='underline decoration-2 decoration-indigo-700'>hacks</b> that made each next try easier than all the ones before. 
        </p>
        <p className="mt-5 ">
          The end-result of all these trial and errors was my collection of <b>amazing shortcuts</b> that made my 
          miraculous transformation <b>3.5x faster</b> and allowed me to remain slim for years to follow. 
        </p> 
        <p className="mt-5 ">
          The ease of weight loss was remarkable and physical health benefits have been nothing short of life changing…
        </p> 
        <p className="mt-5  font-bold">
          <span className='bg-yellow-200'>My future health looks even brighter…</span>
        </p> 
        <p className="mt-5 ">
          But honestly, that’s nowhere close to what matters to me the most…
        </p> 
        <p className="mt-5 ">
          What matters most to me is that when I look in the mirror I can’t help but to be proud of the person I’ve become. 
        </p> 
        <p className="mt-5 ">
          Today, I can say, with a genuine smile, that <b>I don’t hate myself anymore</b>…
        </p> 
        <p className="mt-5 ">
          These <b>truly amazing secrets</b> haven't been published ever before… 
        </p>
        <p className="mt-5 ">
          I have a personal name for this weight loss miracle – <b>“Eat To Burn”</b>. It works like crazy.
        </p>
        <p className="mt-5 ">
          Look I don't mean to brag, but darn it, I'm pretty proud of myself. 
        </p>
        <p className="mt-5 ">
         The doctors couldn't help me. All those diet "experts" could not help me. Even my own family couldn't help me. 
         (But God bless them for trying). 
        </p>
        <p className="mt-5 ">
          I didn't get help from any of the commercial weight loss programs or anybody else. 
          No, I did it on my own and I learned how to do it cheap. 
        </p>
        <p className="mt-5 ">
          I kept very thorough notes of every one of my failed attempts… 
        </p>
        <p className="mt-5 ">
          Read pages of latest research and top scientific discoveries in weight loss science… 
        </p>
        <p className="mt-5 ">
          Spend hours on social media talking to real women, who just like me have struggled and finally succeed in breaking out of overeating prison…. 
        </p>
        {/* <p className="mt-5 ">
         It is so simple, yet so amazingly powerful, that anyone - anyone - can use it to turn their weight loss into a fun and easy game. 
        </p> */}
        <p className="mt-5 ">
          I have put every last one of my miracle tricks for losing weight fast into a small, easy to read book.
        </p>
        <h2 className="my-10 mx-auto sm:my-16 font-semibold text-xl/7 sm:text-3xl lg:max-w-5xl text-center">
          It is so simple, yet so amazingly powerful, that <span className='text-red-500'>anyone - anyone - can use it to turn their <b className='underline'>weight loss </b>into a <b className='underline'>fun and easy game</b></span>. 
        </h2>
        {/* <h2 className="mt-5 text-xl sm:text-3xl"> 
          I have put every last one of my miracle tricks for losing weight fast and promoting health into a small, easy to read book.
        </h2> */}
        {/* <h2 className="mt-5 mx-auto capitalize text-center text-xl sm:text-3xl md:max-w-5xl"> 
          Every last one of my  
          <span className='font-semibold'> miracle tricks </span> for 
          <span className='font-semibold'> losing weight fast</span> and 
          <span className='font-semibold'> promoting health</span> is inside this{' '}    
          <span className='bg-yellow-100 font-semibold underline capitalize'>Easy to read book</span>.
        </h2> */}
        {/* <p className="mt-5 ">
        The book is called "Eat To Burn". And I'm giving away a small number of  copies absolutely 100% free.  
        </p> */}
        </div>
        {/* <h2 className="max-w-[89%] mx-auto mt-20 text-center text-xl/7 font-semibold lg:text-3xl">
        Here are just a few of the remarkable keto diet shortcuts you will find in this book:
        </h2> */}
        <BookDisplayBottom />
        <ActionButton onOpenModal={handleModalOpen}/>
        <h2 className="mt-10 mx-auto font-bold capitalize text-center text-xl sm:text-3xl lg:max-w-3xl lg:mt-20">
          Here are just a few of the remarkable keto diet shortcuts you will find in this book:
        </h2>
        {/* Bullets Container */}
        <ContentBullets />
        <HighlightHeader />
        <ActionButton onOpenModal={handleModalOpen}/>
        {/* <p className="mt-5 text-xl/8">
        My FREE book contains every single one of my get-thin-fast keto shortcuts. You can learn all of these amazing secrets 
        <strong className='font-semibold'> TODAY for FREE</strong>…
        </p> */}

        <p className="mt-5 mx-auto max-w-4xl text-center text-2xl/8 lg:text-3xl lg:mt-12">
        Can keto diet stoke your metabolism to boost my own body’s ability to burn fat naturally? 
        <span className='underline decoration-indigo-600 bg-yellow-100'> You bet it can! </span>
        </p>

        {/* Keto Wins Conteiner */}
        <KetoWins />
        {/* <div className='max-w-[90%] mx-auto text-lg/7 lg:text-xl/8'> */}
        <div className='mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8'>   
        <p className="mt-2 text-3xl font-md tracking-tight text-gray-900 sm:text-4xl">
        Are any of these people different from you? <span className='font-bold'>No, they are not</span>. 
        </p>
        <p className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
        There are regular everyday people just like you… 
        </p>
        <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900">
        Everyone is getting frustrated by the slow process…  
        </p>
        <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900">
        Everyone is falling off the wagon at some point …          
        </p>
        <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900 ">
        Everyone is hitting a plateau and feeling like giving up…
        </p>
        <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900">
          The only thing they did was put their doubts aside… and imagine what their lives would be like if they never made that decision to change! 
        </p>
        <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900">
        If you, too, can simply put your doubts aside for a short time, you can see yourself liberated from the prison of your old body… 
        </p>
       
        {/* <p className="mt-5 ">
        You can gain more than just a life... 
          You can gain many years of amazingly happy and healthy life!
        </p>
        <p className="mt-5 ">
        Get the free book while spots are still available
        </p> */}

        </div>
        <ProgramHighlight/>
        <ActionButton onOpenModal={handleModalOpen}/>
        {/* <div className='max-w-[90%] mx-auto text-lg/7 lg:text-xl/8'>
          <h2>Special bonus:<br/>
30 days FREE trial to EatToBurn Personal Plan
        </h2>
        <p className="mt-5 ">
          With your free copy of "Eat To Burn", you also get a 30-day FREE  trial of Eat To Burn Personal Plan!
        </p>
        <p className="mt-5 ">
          Your personal plan comes loaded cover to cover with the most valuable insights on keto diet relevant to you.
        </p>
        <p className="mt-5 ">
        No matter what your personal goals are or health concerns you may be coping with…
        </p>
        <p className="mt-5 ">
        You’ll discover your personal plan to be an outstanding source of useful suggestions specific to you and not published anywhere else.
        </p>
        <p className="mt-5 ">
        And remember, it costs you nothing.
        </p>

        </div> */}
        <MemberBenefits/>
        <BottomCTA />
        {/* <CheckoutForm /> */}
        <Footer />
        <BottomBanner onOpenModal={handleModalOpen}/>

        <OrderModal 
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          totalAmount="$10.65"
        />

      </div>
    </div>
  )
}
