import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { newOptin, reset } from '../../../features/optin/optinSlice';
import { usePostHog, useFeatureFlagVariantKey } from 'posthog-js/react'


export default function NewsletterLandingVariant0() {

  // PostHog AB Tests
  // Store heallines as array for easy line breaks 
  const headlinesInTest = {
    control: ['Eat To Burn Your Way', 'To Slimmer & Happier You'],
    variantOne: ['45 Lbs Lighter and', 'A Lot Happier...']
  }

  const posthog = usePostHog()
  // States for PostHog A/B tests
  // subPriceState is used for setting subscription price selected from PostHog experiment
  const [headlineState, setHeadlineState] = useState(headlinesInTest.control)
  // console.log(headlineState)
  // posthog.featureFlags.override({'e2b-nl-landing-page-headline': 'variant-1'})
  const headlineVariant = useFeatureFlagVariantKey('e2b-nl-landing-page-headline')
  
  useEffect(() => {
    if (headlineVariant === 'variant-1') {
      setHeadlineState(headlinesInTest.variantOne)
    }
  }, [headlineVariant])

  const thankYouPage = '/thankyou-v0'  
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();  
  
  const [ optinData, setOptinData] = useState({
    email: '',
    referrer: document.referrer,
    hostname: window.location.hostname,
    userAgent: window.navigator.userAgent,
    screenWidth: window.innerWidth,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    language: window.navigator.language,
    platform: window.navigator.platform,
    latitude: '',
    longitude: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  const { email } = optinData;

  const { data, isLoading, isError, isSuccess, message} = useSelector(
    (state) => state.optin
  )

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    setOptinData(prevData => ({
      ...prevData,
      utmSource: params.get('utm_source') || '',
      utmMedium: params.get('utm_medium') || '',
      utmCampaign: params.get('utm_campaign') || '',
    }));
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setOptinData((prevData) => ({
            ...prevData,
            latitude: latitude.toString(),
            longitude: longitude.toString()
          }));
        },
        (error) => {
          // console.log('Geolocation error:', error.code, error.message);
          let locationStatus;
          switch(error.code) {
            case error.PERMISSION_DENIED:
              locationStatus = 'permission denied';
              break;
            case error.POSITION_UNAVAILABLE:
              locationStatus = 'unavailable';
              break;
            case error.TIMEOUT:
              locationStatus = 'timeout';
              break;
            default:
              locationStatus = 'error';
          }
          setOptinData((prevData) => ({
            ...prevData,
            latitude: locationStatus,
            longitude: locationStatus
          }))
        },
        {timeout: 10000} // 10 sec timeout
      );
    } else {
      // console.log('Geolocation is not supported by this browser.')
      setOptinData((prevData)=>({
        ...prevData,
        latitude: 'not supported',
        longitude: 'not supported'
      }))
    }
  
    // if(isSuccess){
    //   navigate('/quiz')
    // }
  
    // dispatch(reset())
  }, [location])  

  useEffect(() => {
    if (isError) {
      console.error('Optin error:', message);
      setErrorMessage(message);
    }
  
    if (isSuccess) {
      // console.log('Optin successful, navigating to /quiz');
      navigate(thankYouPage);
    }
  
    return () => {
      if (isSuccess || isError) {
        // console.log('Resetting optin state');
        dispatch(reset());
      }
    };
  }, [isLoading, isError, isSuccess, message, navigate, dispatch])

  // Method handles form submission and validates email input field.
const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!email || !emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address');
      return false;
    }
  
    return true;
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
  
    if (validateEmail()) {
      // Valid email, proceed with form submission
      // Add your form submission logic here
      // console.log('Sibmitting optin data Page .... ',optinData)
      dispatch(newOptin(optinData))
    }
  };

  const handleChange = (event) => {
    setOptinData((prevState) => ({
      ...prevState,
      email: event.target.value,
    }));
  
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-[#430064] min-h-screen flex flex-col">
      <main className="flex-grow flex items-center justify-center px-4 py-16">
        <div className="max-w-4xl w-full text-center">
          <div className="mb-8">
            <img 
              src="https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80/uploads/publication/logo/d0d8b620-907e-4a54-b21c-fda0d74e4a52/thumb_android-chrome-512x512-pruple.png" 
              alt="Eat To Burn" 
              className="w-24 h-24 mx-auto rounded-lg shadow-lg"
            />
          </div>
          
          <h1 className="font-arial font-bold text-3xl sm:text-4xl mb-4 text-[#FFE600]">
            {/* Eat To Burn Your Way<br />To Slimmer & Happier You */}
            
            {headlineState.map((line, index) => (
              <React.Fragment key={index}>
              {line}
              {index < headlineState.length - 1 && (
                <br className="block" />
              )}
            </React.Fragment>
            ))}
          </h1>
          
          <p className="font-helvetica text-lg sm:text-xl mb-8 text-[#FCF8FF]">
            Turn off all <em>food cravings</em>, boost your<br />
            mood and confidence through the roof 🚀<br />
            with our FREE 5 minute newsletter read...
          </p>
          
          <form onSubmit={handleSubmit} className="max-w-lg mx-auto mb-6">
            <div className="flex flex-col sm:flex-row gap-2">
              <input 
                type="email" 
                placeholder="Enter Your Email" 
                required
                value={email}
                onChange={handleChange}
                className="flex-grow py-3 px-4 text-lg text-gray-900 bg-[#F9FAFB] rounded-lg focus:outline-none"
              />
              <button 
                type="submit"
                className="py-3 px-6 text-lg font-semibold text-white bg-[#58A11E] rounded-lg hover:bg-[#4C8A1A] transition duration-300"
              >
                Sign Up
              </button>
            </div>
            {errorMessage && <p className="text-white">{errorMessage}</p>}
          </form>
          
          <p className="text-md sm:text-lg text-[#FCF8FF]">
            Join 3,588 readers today and get bite sized expert keto advice.
          </p>
        </div>
      </main>
      
      <footer className="text-center py-4">
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4 text-[#FFE600] text-xs sm:text-sm">
          {/* <p>
            A <a href="https://beehiiv.com" target="_blank" rel="noopener noreferrer" className="underline">beehiiv</a> newsletter
          </p> */}
          <p>
            <a href="https://beehiiv.com/privacy" target="_blank" rel="noopener noreferrer" className="underline opacity-70">Privacy Policy</a>
          </p>
          <p>
            <a href="https://beehiiv.com/tou" target="_blank" rel="noopener noreferrer" className="underline opacity-70">Terms of Use</a>
          </p>
        </div>
      </footer>
    </div>
  );
}