import {
    ArrowUturnLeftIcon,
    ChatBubbleLeftEllipsisIcon,
    DocumentChartBarIcon,
    HeartIcon,
    InboxIcon,
    PencilSquareIcon,
    TrashIcon,
    UsersIcon,
  } from '@heroicons/react/24/outline'
  
  const features = [
    {
      name: 'Metabolism Makeover',
      description: 'Actionable advice to help you navigate the keto lifestyle and achieve lasting weight loss',
      icon: InboxIcon,
    },
    {
      name: 'Fat-Burning Playbook',
      description: 'Discover "Eat Smart - Burn More" secret weapon against stubborn pounds',
      icon: UsersIcon,
    },
    {
      name: 'Hack Your Plate, Shrink Your Waist',
      description: 'VIP access to the weight loss shortcuts to finally crack the code on your ideal body.',
      icon: TrashIcon,
    },
    {
      name: 'Outsmart Your Cravings',
      description: 'Insider knowledge to conquer your metabolism - the key to fat-burning freedom',
      icon: PencilSquareIcon,
    },
    // {
    //   name: 'Team Reporting',
    //   description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    //   icon: DocumentChartBarIcon,
    // },
    // {
    //   name: 'Saved Replies',
    //   description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    //   icon: ArrowUturnLeftIcon,
    // },
    // {
    //   name: 'Email Commenting',
    //   description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    //   icon: ChatBubbleLeftEllipsisIcon,
    // },
    // {
    //   name: 'Connect with Customers',
    //   description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
    //   icon: HeartIcon,
    // },
  ]
  
  export default function FeaturesSection() {
    return (
      <div className="bg-purple-900">
        <div className="mx-auto max-w-4xl px-6 py-24 sm:py-32 lg:max-w-7xl lg:px-8 lg:py-56">
          <h2 className="text-4xl font-bold tracking-tight text-white">We know staying in ketosis can be hard. Here’s how you can shed stubborn pounds – without feeling deprived.</h2>
          {/* <p className="mt-6 max-w-3xl text-lg/8 text-indigo-200">
            Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis. Blandit aliquam
            sit nisl euismod mattis in.
          </p> */}
          <div className="mt-20 grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name}>
                {/* <div>
                  <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white/10">
                    <feature.icon aria-hidden="true" className="size-8 text-white" />
                  </span>
                </div> */}
                <div className="mt-6 border p-4 rounded-lg">
                  <h3 className="text-xl/8 lg:text-2xl font-semibold text-white">{feature.name}</h3>
                  <p className="mt-2 text-lg/7 lg:text-xl text-indigo-200">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  