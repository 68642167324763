import {Link} from 'react-router-dom';

export default function CallToActionBottom() {
    return (
      // <div className="bg-indigo-100">
      //   <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
      //     <h2 className="max-w-2xl text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
      //       Ready to dive in? <br />
      //       Start your free trial today.
      //     </h2>
      //     <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:shrink-0">
      //       <a
      //         href="#"
      //         className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      //       >
      //         Get started
      //       </a>
      //       <a href="#" className="text-sm/6 font-semibold text-gray-900">
      //         Learn more <span aria-hidden="true">→</span>
      //       </a>
      //     </div>
      //   </div>
      // </div>
      <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">
           Rev up your metabolism and finally shed those stubborn pounds
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-pretty text-lg/8 lg:text-xl text-gray-300">
          Learn fun and easy "hacks" to outsmart food cravings, boost your metabolism and burn fat like a kid again. 
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/quiz"
              className="rounded-md bg-orange-500 px-7 py-2.5 text-xl lg:text-2xl font-semibold text-gray-100 shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Get started
            </Link>
            {/* <a href="#" className="text-sm/6 font-semibold text-white">
              Learn more <span aria-hidden="true">→</span>
            </a> */}
          </div>
          <svg
            viewBox="0 0 1024 1024"
            aria-hidden="true"
            className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          >
            <circle r={512} cx={512} cy={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    )
  }
  