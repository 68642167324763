import React from 'react';
import HeroSection from '../../../components/newsletter/thankyou/v1HeroSection';
import ContentSection01 from '../../../components/newsletter/thankyou/v1ContentSection01';

export default function NewsletterThankYouVariant0() {
    return(
        <> 
            <HeroSection />
            <ContentSection01 />
        </>
    )
}