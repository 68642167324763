import { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { newMealplanRecord, updateMealplanRecord } from '../../../features/mealplan/mealplanSlice';
import { newQuizRecord } from '../../../features/quiz/quizSlice';

import { XlviLoader } from "react-awesome-loaders";
import TextTransition, { presets } from 'react-text-transition';

const Results = (props) => {

  // console.log("Props in RESULTS ", props)
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const buyerData = useSelector(state => state.buyer.data); // Get buyer data value
  
  const TEXTS = [
      'Preparing your results',
      'Reviewing quiz answers', 
      'Analizing your goals', 
      'Calculating your BMR',
      'Computing ideal caloric intake',
      'Creating your personal plan'
  ];

  useEffect(() => {
      const intervalId = setInterval(() => {
        // () => setIndex((index) => index + 1),
        // 3000, // every 3 seconds

        // create new function to set index
        setIndex((prevIndex) => {
          // If the next index is the last one in the text array
          if (prevIndex === TEXTS.length - 1){
            setTimeout(() => {
              // Use navigate function to redirect to internal router pages
              // navigate('/myplan', {quizResults: {quiz: props.quizData, survey: props.surveyData}})
              
              // Redirect based on buyer data value 
              // Route 1 if user completes quiz after purchase (buyerData exists) send to register page
              // Route 2 if user completes quiz before purchase (buyerData is null) send to plan summary pitch page
              navigate(buyerData === null ? '/myplan' : '/register');

              // Redirect to custom plan summary pitch page
              // navigate('/myplan')

              // Use this line to navigate to an outside/3rd party url (This shows Unbounce page)
              // window.location.href = 'https://hello.eattoburn.com/myplan/';
            }, 2000); // Wait 3 seconds
          }
          return prevIndex + 1;
        })
      }, 3000);

      return () => clearTimeout(intervalId);
    });

  useEffect(() => {
    const createMealplanWithBuyerData = async () => {
      try {
        // Create new mealplan
        const newMealplan = await dispatch(newMealplanRecord(props.surveyData));
        // console.log(newMealplan)
        // Create new quiz results record
        await dispatch(newQuizRecord(props.quizData));

        if (buyerData) {
          // Get mealplanId and ensure it's a clean string
          const mealplanId = newMealplan?.mealplanId || localStorage.getItem('mealplanId')?.replace(/['"]+/g, '');

          if (!mealplanId) {
            throw new Error('No mealplanId available');
          }

          const updateData = {
            mealplanId,
            mealplanUpdateData: {
              buyerId: buyerData.buyerId,
              optinId: buyerData.optinId,
              buyer: true,
              beehiivSubId: buyerData.beehiivSubId
            }
          }
          
          await dispatch(updateMealplanRecord(updateData)).unwrap();

        }
      } catch(error) {
        console.log('Failed to handle mealplan operation: ', error);
      }
    }

    createMealplanWithBuyerData();
    // console.log('surveyData:', props.surveyData);
    // console.log('buyerData:', buyerData);
    // console.log('mealplanId available:', !!props.surveyData?.mealplanId);
    // console.log('buyerId available:', !!buyerData?.data?.buyerId);

    // dispatch(newMealplanRecord(props.surveyData));
    // dispatch(newQuizRecord(props.quizData));

    // console.log('Buyerdata after quiz complete ', buyerData)
    // // Check buyer.data in redux store if buyer.data not null update mealplan record with buyerId & optinId, set buyer value true
    // if (buyerData){
  
    //   const updateData = {
    //     mealplanId: mealplanData.mealplanId,
    //     buyerId: buyerData.buyerId,
    //     optinId: buyerData.optinId,
    //     buyer: true,
    //     beehiivSubId: buyerData.beehiivSubId
    //   }

    //   console.log('Attempting to update mealplan: ', updateData);
    //   dispatch(updateMealplanRecord(updateData))
    //   .unwrap()
    //   .then((result) => {
    //     console.log('Mealplan update success ', result);
    //   })
    //   .catch((error) => {
    //     console.error('Failed to update mealplan: ', error)
    //   })
    // }  
  },[dispatch, props.quizData, props.surveyData, buyerData])

  return (
    <>
      <div className="flex min-w-full min-h-screen justify-center">
          <div className="mt-40 mr-10 ">
              <div className="text-base pl-6 pb-10 font-semibold text-indigo-600">
                <TextTransition springConfig={presets.stiff}>{TEXTS[index % TEXTS.length]}</TextTransition>
              </div>    
              <XlviLoader
                  boxColors={["#36EEE0", "#F652A0", "#F8D210"]}
                  desktopSize={"128px"}
                  mobileSize={"100px"}
              />
          </div>
      </div>
    </>
  )
}

export default Results