// import {useEffect, useState} from 'react';
// import { Elements, } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import axios from 'axios';
// import PaymentForm from './v1PaymentForm';

// const serverSideBaseAPI = 'http://localhost:5000/api';
// // const serverSideBaseAPI = process.env.REACT_APP_SERVERSIDE_BASE_API;
// console.log(serverSideBaseAPI)
// const PaymentFormWraper = ({bump, name, email, price, onBumpChange, onStepChange}) => {

//     const [stripePromise, setStripePromise] = useState(null);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchPublishableKey = async ( )=>{
//             try{
//                 const response = await axios.get(serverSideBaseAPI +'/buy/config');
//                 const {publishableKey} = response.data;
//                 const stripeInstance = await loadStripe(publishableKey);
//                 setStripePromise(stripeInstance)
//             }catch(error){
//                 setError(error.message);
//                 console.error('Error loading Stripe:', error);
//             }
//         }
//         fetchPublishableKey();
//       }, [])

//     if (error) {
//         return <div>Error loading payment system: {error}</div>;
//     }

//     if (!stripePromise) {
//         return <div>Loading payment system...</div>;
//     }

//     return(
//         <>
//         {
//         stripePromise && 
//             (
//                 <Elements stripe={stripePromise} >
//                     <PaymentForm 
//                         bump={bump} 
//                         name={name}
//                         email={email}
//                         price={price}
//                         onBumpChange={onBumpChange} 
//                         onStepChange={onStepChange}
//                         // sub={stripeSubscription}
//                         // intentId={stripePaymentIntent}
//                         />
//                 </Elements>
//             )
//         }
//         </>
//     )

// }

// export default PaymentFormWraper

import {useEffect, useState} from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
import PaymentForm from './v1PaymentForm';

// const serverSideBaseAPI = 'http://localhost:5000/api';
const serverSideBaseAPI = process.env.REACT_APP_SERVERSIDE_BASE_API;

const PaymentFormWraper = ({bump, name, email, price, onBumpChange, onStepChange}) => {
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        const fetchPublishableKey = async () => {
            const response = await axios.get(serverSideBaseAPI +'/buy/config');

            const {publishableKey} = response.data;
            // console.log({publishableKey})
            setStripePromise(loadStripe(publishableKey))
        }
        fetchPublishableKey();
    }, [])

    return(
        <>
            {stripePromise && (
                <Elements stripe={stripePromise}>
                    <PaymentForm 
                        bump={bump} 
                        name={name}
                        email={email}
                        price={price}
                        onBumpChange={onBumpChange} 
                        onStepChange={onStepChange}
                    />
                </Elements>
            )}
        </>
    )
}

export default PaymentFormWraper