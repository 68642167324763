
  import {CheckBadgeIcon } from '@heroicons/react/20/solid'
  
  const features = [
    {
      name: 'Push to Deploy',
      description: 'Personalized meal plan with easy to follow recipes that are ready in 20 minutes or less…',
      icon: CheckBadgeIcon,
    },
    {
      name: 'SSL Certificates',
      description: 'You’ll know exactly what, when and how much to eat to reach your weight goals without guessing or counting calories…',
      icon: CheckBadgeIcon,
    },
    {
      name: 'Simple Queues',
      description: "1,000's of recipes for breakfast, lunch and dinner… Plus healthy, low-carb snack options.",
      icon: CheckBadgeIcon,
    },
    {
      name: 'Advanced Security',
      // description: 'Each meal includes only ingredients that you choose for yourself (Enjoy your favorite proteins, fats and carbs with each and every meal).',
      description: 'Enjoy your favorite proteins, fats and carbs with each and every meal.',

      icon: CheckBadgeIcon,
    },
    {
      name: 'Powerful API',
      description: 'Fastest and "easy as 1-2-3" ways to get into and stay in ketosis… ',
      icon: CheckBadgeIcon,
    },
    {
      name: 'Database Backups',
      description: 'With this Sneaky Little Secret you\'re guaranteed to outsmart carb cravings everytime… ',
      icon: CheckBadgeIcon,
    },
  ]
  
  export default function MemberBenefits() {
    return (
      <div className="relative bg-white py-24 sm:py-32 lg:py-24">
        <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
          <h2 className="text-3xl lg:text-5xl font-bold text-red-700">Special bonus:</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          30 days FREE trial to EatToBurn Personal Plan
          </p>
          <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900">
          With your free copy of "Eat To Burn", you also get a<span className='font-bold'> 30-day FREE  trial of Eat To Burn Personal Plan</span>!
          </p>
          <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900">
          Your personal plan comes loaded cover to cover with the<span className='font-bold'> most valuable insights on keto diet relevant to you</span>.
          </p>
          <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900">
          No matter what your personal goals are or health concerns you may be coping with…
          </p>
          <p className="mx-auto mt-5 max-w-prose text-left text-xl lg:text-[22px]/9 text-gray-900">
          You’ll discover your personal plan to be an outstanding source of useful suggestions specific to you and not published anywhere else.
          </p>
          <p className="mx-auto mt-5 font-bold max-w-prose text-left lg:text-[22px]/9 text-xl text-gray-900">
          And remember, it costs you nothing.
          </p>
          <div className="mt-20">
            <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root rounded-lg bg-purple-100 px-6 pb-12">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex text-xl items-center justify-center rounded-xl bg-green-500 p-3 shadow-lg">
                          <feature.icon aria-hidden="true" className="h-10 w-10 text-purple-50" />
                        </span>
                      </div>
                      {/* <h3 className="mt-8 text-lg/8 font-semibold tracking-tight text-gray-900">{feature.name}</h3> */}
                      <p className="mt-5 text-xl/7 lg:text-[22px]/9 font-semibold text-gray-950 h-36">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  