export default function LetterStarter(){
    return(
      //   <div className="w-full my-16 py-6 bg-yellow-100">
      //   <div className="container mx-auto px-4">
      //     <div className="max-w-4xl mx-auto">
      //       <h2 className="text-2xl md:text-3xl text-center font-md leading-relaxed">
      //         If You dream about strolling into a clothing store and{' '}<br/>
      //         <b className="font-bold">
      //         buying regular-size clothes off the "ON SALE" rack
      //         </b>
      //         ..., <br/>
      //         (Being a girl in a clothing store is hard.<br/>
      //          <b className="underline decoration-2"> Being heavy can sometimes feel devastating…</b>)
      //         {/* You'll Also Get A{' '}
      //         <u className="underline decoration-2">FREE Subscription</u>{' '}
      //         To The 'Behind The Scenes' Walkthroughs -{' '}
      //         <b className="font-bold">
      //           A $97 Month Value FOR{' '}
      //           <u className="underline decoration-2">FREE</u>
      //         </b>
      //         ! */}
      //       </h2>
      //     </div>
      //   </div>
      // </div>

      <section className="bg-yellow-100 mt-12 py-10 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
            {/* <img
              alt=""
              src="https://tailwindui.com/plus/img/logos/tuple-logo-gray-900.svg"
              className="h-12 self-start"
            /> */}
            <figure className="mt-10 flex flex-auto flex-col justify-between">
              <blockquote className="text-xl sm:text-2xl/8 text-gray-900">
                <p>
                  “... It must be nice to not have to shop at big and tall stores. Walking into Dillard's and buying off the rack clothes ON SALE.”
                </p>
              </blockquote>
              <figcaption className="mt-10 flex items-center gap-x-6">
                <img
                  alt=""
                  src="https://www.redditstatic.com/avatars/defaults/v2/avatar_default_4.png"
                  className="w-10 h-10 rounded-full bg-gray-50"
                />
                <div className="text-base">
                  <div className="font-semibold text-gray-900">u/BigTexan1492</div>
                  {/* <div className="mt-1 text-gray-500">CEO of Tuple</div> */}
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="flex flex-col border-t border-gray-900/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
            {/* <img
              alt=""
              src="https://tailwindui.com/plus/img/logos/reform-logo-gray-900.svg"
              className="h-12 self-start"
            /> */}
            <figure className="mt-10 flex flex-auto flex-col justify-between">
              <blockquote className="text-xl sm:text-2xl/8 text-gray-900">
                <p>
                  “Being a girl in a clothing store is hard. Beeing heavy is devastating”
                </p>
              </blockquote>
              <figcaption className="mt-5 flex items-center gap-x-6">
                <img
                  alt=""
                  src="https://www.redditstatic.com/avatars/defaults/v2/avatar_default_6.png"
                  className="w-10 h-10 rounded-full bg-gray-50"
                />
                <div className="text-base">
                  <div className="font-semibold text-gray-900">u/holamynameismike182</div>
                  {/* <div className="mt-1 text-gray-500">CEO of Reform</div> */}
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
    )
}