import { FaArrowRight } from "react-icons/fa";

export default function Example() {
    return (
      <div className="bg-white">
        <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-6">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-balance text-4xl font-bold tracking-tight text-red-700 sm:text-5xl">
            It’s Easy to Get This FREE Book<br/>{''}
            With Our Special Offer!
            </h2>
            {/* <p className="mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-gray-600">
              Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur
              commodo do ea.
            </p> */}
            <p className='mt-6 text-lg lg:text-2xl leading-8 text-gray-600 text-left max-w-[90%] mx-auto relative'>
            {/* <p className="mt-6 text-lg leading-8 text-gray-600 flex items-center justify-center "> */}
            <FaArrowRight className='float-left mr-2 -mt-2 w-9 h-9 text-red-500'/> 
            Yes! I want to recieve my <strong>FREE copy of The Eat To Burn</strong>, and begin my 30-day trial subscription to Eat To Burn Personal plan immediately. I can cancel it anytime.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-green-600 lg:px-24 px-12 py-5 text-xl lg:text-2xl lg:text=2xl font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Reserve My Free Copy
              </a>
              {/* <a href="#" className="text-sm/6 font-semibold text-gray-900">
                Learn more <span aria-hidden="true">→</span>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  