import { CheckCircleIcon, CheckBadgeIcon } from '@heroicons/react/20/solid'


const bullets = [
    {
      number: 1,
      text1:
        'Discover No-Fail trick that turns off all food cravings...',
      text2:
        '(This time-tested method is so poweful, it makes dropping pounds easy as 1-2-3). (Page 26)'
    },
    {
    number: 2,
    text1:
        "How to effortlessly break through weight loss plateaus... ",
    text2:
        "without feeling like you're on a diet. (Page 23)"
    },
    {
    number: 3,
    text1:
        'At last, Your carb demons will vanish almost overnight so you can put down the fork with ease when you are actually full not when your stomach ready to explode.',
    text2:
        '(Discover how on Page 15)'
    },
    {
    number: 4,
    text1:
        'Why is it impossible to lower A1C or manage Type 2 diabetes on a standard American diet? ...',
    text2:
        'and why do top researchers agree this easy eating habit is the FASTEST way to feel better mentally, and live pain and depression free? (Page 24)'
    },    
    {
    number: 5,
    text1:
        'Discover ‘cheat meal’ twist so powerful, it triggers 17x faster fat burn!',
    text2:
        '(This time-tested method is so effective that the weight loss industry wants to keep it hidden away.) (Page 12)'
    },    
    {
    number: 6,
    text1:
        'How to transform your body in the shortest time possible... while you improve your memory at the same time!',
    text2:
        '(No more brain fog, or feeling slow, tired, and “blah”.) (Page 13)'
    },    {
    number: 7,
    text1:
        'Finally, the Ultra-powerful "FLEX" plan puts you on the FAST track to buying off-the-rack clothes at your favorite department store... not big and tall!',
    text2:
        '(You can finally blend with the crowd - No more feeling like you\'re being \"looked at\" anytime you are out in public.) (Page 19)'
    },    {
    number: 8,
    text1:
        'Learn a shockingly EASY twist that turns back the clock even on over-40 metabolism and start burning fat like a kid again. ',
    text2:
        '(The older you are the better this works.) (Page 13)'
    },
    {
    number: 9,
    text1:
        'Study-backed FUN and EASY eating habit makes slimming down feel like a game without diet math and hunger.',
    text2:
        '(Page 33)'
    },
    {    
    number: 10,
    text1:
        'Age in reverse... Discover a low-cost science-tested hack to boost your mid-day energy that has mind-blowing “Benjamin Button” side effects for almost everyone who tries it. ',
    text2:
        '(Cutting the grocery bill is a NICE little bonus too) (Page 12)'
    },

  ]

export default function ContentBullets() {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* // <div className="bg-white mx-auto max-w-6xl text-base/7 text-gray-700 border-x-1 px-3 border-grey-300"> */}
                <ul role="list" className="my-8 space-y-8">
                    {
                        bullets.map((bullet) => (
                            <li key={bullet.number} className="py-8 px-6 flex gap-x-3 overflow-hidden rounded-lg shadow-xl">
                                <CheckBadgeIcon aria-hidden="true" className="mt-1.5 h-8 w-8 flex-none text-green-500" />
                                
                                <span className="text-lg/8 sm:text-2xl">
                                    <strong className="font-semibold">{bullet.text1}</strong>{' '}
                                    {bullet.text2}    
                                </span>
                            </li>
                        ))
                    }

                    {/* <li className="flex gap-x-3">
                        <CheckCircleIcon aria-hidden="true" className="mt-1.5 h-6 w-6 flex-none text-indigo-600" />
                        <span className="text-xl/8">
                            <strong className="font-semibold">How to effortlessly break through weight loss plateaus... without feeling like you're on a diet.</strong>{' '}
                        </span>
                    </li>
                    <li className="flex gap-x-3">
                        <CheckCircleIcon aria-hidden="true" className="mt-1.5 h-6 w-6 flex-none text-indigo-600" />
                        <span className="text-xl/8">
                            <strong className="font-semibold">Your carb demons will vanish almost overnight so you can put down 
                                the fork with ease when you are actually full not when your stomach ready to explode.
                            </strong>{' '}
                        </span>
                    </li> */}
                </ul>
            {/* // </div> */}
        </div>
    )
}