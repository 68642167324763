import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { newBuyer} from '../../../../features/buyer/buyerSlice';
import { useNavigate } from "react-router-dom";
// import {updateMealplanRecord} from '../../../../features/mealplan/mealplanSlice';
import CardFormContainer from './v1CardFormContainer';

import HackFitness from '../../../../assets/img/product/HackingFitnessGuide.png'
//import BulletProofKeto from '../../../../assets/img/bonuses/bulletproofCD.png'
import BulletProofKeto from '../../../../assets/img/bonuses/image14.jpg'

// PostHog feature flag for price testing
import { usePostHog, useFeatureFlagVariantKey } from 'posthog-js/react'

const serverSideBaseAPI = process.env.REACT_APP_SERVERSIDE_BASE_API;

// const beehiiveEndPointUrl = process.env.REACT_APP_BEEHIIVE_END_POINT_URL
// const beehiivePubId = process.env.REACT_APP_BEEHIIVE_PUB_ID
// const beehiiveAutId= process.env.REACT_APP_BEEHIIVE_AUT_ID

const PaymentForm = ({name, email, onStepChange}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Testing price variations
    const products = {
      eatToBurnPersonalPlan: {
        regPrice: 34.89,
        testPrice_1: 9.99,
        testPrice_2: 19.99,
        testPrice_3: 27.99,
        salePrice: 0.00
      },
      hackingFitness: {
        regPrice: 39.98,
        testPrice_1: 9.98,
        testPrice_2: 12.98,
        testPrice_3: 24.98,
        salePrice: 19.98
      },
      bulletProofKeto: {
        regPrice: 89.99,
        salePrice: 34.99,
        testPrice_1: 27.99,
        testPrice_2: 47.99,
        testPrice_3: 54.99
      }
    }
    const stripe = useStripe();
    const elements = useElements();

    const [isChecked, setIsChecked] = useState(false);
    const [upsellOneIsAdded, setUpsellOneIsAdded] = useState(false);
    const [upsellTwoIsAdded, setUpsellTwoIsAdded] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    // setting purchase to true if card charge is success
    const [purchase, setPurchase] = useState(false);

    // States for PostHog A/B tests
    // subPriceState is used for setting subscription price selected from PostHog experiment
    const [subPriceState, setSubPriceState] = useState(products.eatToBurnPersonalPlan.regPrice)
    const [hfPriceState, setHfPriceState] = useState(products.hackingFitness.salePrice)
    const [bpkPriceState, setBpkPriceState] = useState(products.bulletProofKeto.salePrice)

    const posthog = usePostHog()
    // posthog.featureFlags.override({'e2b-subscription-price': '2799'})
    // posthog.featureFlags.override({'hf-upsell-prices': '2498'})
    // posthog.featureFlags.override({'bpk-upsell-price': '4799'})
    const subPriceVariant = useFeatureFlagVariantKey('e2b-subscription-price')
    const hfPriceVariant =  useFeatureFlagVariantKey('hf-upsell-prices')
    const bpkPriceVariant = useFeatureFlagVariantKey('bpk-upsell-price')

    useEffect(() => {

      // Subscription Price Variants
      if (subPriceVariant === '999'){
        setSubPriceState(products.eatToBurnPersonalPlan.testPrice_1)
      }
      else if (subPriceVariant === '1999'){
        setSubPriceState(products.eatToBurnPersonalPlan.testPrice_2)
      }
      else if (subPriceVariant === '2799'){
        setSubPriceState(products.eatToBurnPersonalPlan.testPrice_3)
      }

      // Hacking Fitness upsell variants
      if (hfPriceVariant === '998'){
        setHfPriceState(products.hackingFitness.testPrice_1)
      }
      else if (hfPriceVariant === '1298'){
        setHfPriceState(products.hackingFitness.testPrice_2)
      }
      else if (hfPriceVariant === '2498'){
        setHfPriceState(products.hackingFitness.testPrice_3)
      }

      // Bulletproof Keto upsell variants
      if (bpkPriceVariant === '2799'){
        setBpkPriceState(products.bulletProofKeto.testPrice_1)
      }
      else if (bpkPriceVariant === '4799'){
        setBpkPriceState(products.bulletProofKeto.testPrice_2)
      }
      else if (bpkPriceVariant === '5499'){
        setBpkPriceState(products.bulletProofKeto.testPrice_3)
      }
    }, [subPriceVariant, hfPriceVariant, bpkPriceVariant])

    
    if (!upsellOneIsAdded && !upsellTwoIsAdded){
      var orderTotal = products.eatToBurnPersonalPlan.salePrice
      var bumpItems = []
      // var orderTotal = subPriceState
      
    } 
    else if (upsellOneIsAdded && !upsellTwoIsAdded){
      // orderTotal = products.eatToBurnPersonalPlan.salePrice + products.hackingFitness.salePrice
      orderTotal = (products.eatToBurnPersonalPlan.salePrice + hfPriceState).toFixed(2)
      bumpItems = [hfPriceState]
    }
    else if (!upsellOneIsAdded && upsellTwoIsAdded){
      // orderTotal = products.eatToBurnPersonalPlan.salePrice + products.bulletProofKeto.salePrice
      orderTotal =  (products.eatToBurnPersonalPlan.salePrice + bpkPriceState).toFixed(2)
      bumpItems = [bpkPriceState]
    }
    else if (upsellOneIsAdded && upsellTwoIsAdded){
      // orderTotal = products.eatToBurnPersonalPlan.salePrice + products.hackingFitness.salePrice + products.bulletProofKeto.salePrice
      orderTotal = (products.eatToBurnPersonalPlan.salePrice + hfPriceState + bpkPriceState).toFixed(2)
      bumpItems = [hfPriceState, bpkPriceState]
    }

    const apiEndpoint = '/buy/subscribetrialbump';
    // let apiEndpoint;
    // if(bump){
    //     apiEndpoint = '/buy/subscribebump';
    // } else {
    //     apiEndpoint = '/buy/subscribe';
    // }

    const Chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // Creates random 12 chars for buyerId 
    const randomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        return result;
    }

    // and grab beehiivSubId from Redux Store
    const optinData = useSelector((state) => state.optin.data);

    const optinId = optinData ? optinData.newOptinRecord.optinId : '';
    const beehiivSubId = optinData ? optinData.newOptinRecord.beehiivSubId : '';

    const handleSubmit = async (event)=>{
        event.preventDefault();
            
        // Validate checkbox first
        if (!isChecked) {
            setErrorMessage('Please agree to the terms and conditions');
            return;
        }

        if(!stripe || !elements){
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        onStepChange();
        setIsProcessing(true);
        setErrorMessage(''); // Clear any previous errors
        
        // Create payment method
        const paymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {name, email}
        });

        if (paymentMethod.error && (paymentMethod.error.type === 'validation_error' || paymentMethod.error.type === 'card_error')){
            setErrorMessage(paymentMethod.error.message);
            setIsProcessing(false)
            return;
        } 

        try {
          // Send payment method to server
          const {data} = await axios.post(serverSideBaseAPI + apiEndpoint, {
            name,
            email,
            payment_method: paymentMethod.paymentMethod.id,
            sub_price: subPriceState,
            order_items: bumpItems
          });

          let paymentIntent = null;

          // Only confirm payment intent of there are upsell items
          if (bumpItems.length > 0){
            const {client_secret} = data;
            const confirmResult = await stripe.confirmCardPayment(client_secret);

            if (confirmResult.error){
              setErrorMessage(confirmResult.error.message);
              setIsProcessing(false)
              return;
            }

            paymentIntent = confirmResult.paymentIntent;

            if (paymentIntent.status !== 'succeeded'){
              setErrorMessage('Payment failed. Please try again.')
              setIsProcessing(false)
              return;
            }
          }
          
          // Create buyerId  
          const buyerId = randomString(12, Chars);
          
          // Create buyer details with conditional payment intent data
          const BuyerDetails = {
            name,
            email,
            amount: paymentIntent ? paymentIntent.amount : 0,
            created: paymentIntent ? paymentIntent.created : Date.now(),
            intentId: paymentIntent ? paymentIntent.id : null,
            orderBump: upsellOneIsAdded,
            optinId,
            buyerId,
            beehiivSubId,
            mealplanUpsell: false,
            subscription: true,
            subscriptionId: data.subscription,
            customerId: data.customer
          }

          // Update Redux store
          dispatch(newBuyer(BuyerDetails));
          setPurchase(true)

          // Beehiiv Intergration -- add new buyer to automation to recieve email with url 
          // to the Registeration page
          try{
            const beehiivResponse = await axios.post(
              `${serverSideBaseAPI}/beehiiv/add-to-automation`,
              {
                email,
                subscription_id: beehiivSubId
              }
            )

            if (!beehiivResponse){
              console.warn('Beehiiv integration returned empty response');
            }  
            console.log(beehiivResponse)
            // const beehiiveApiCallUrl = `${beehiiveEndPointUrl}publications/${beehiivePubId}/automations/${beehiiveAutId}/journeys`;
            // await axios.post(beehiiveApiCallUrl, {
            //   email,
            //   subscription_id: beehiivSubId
            // })
          } catch(error){
            console.error('Beehiive automation error:', error);
          }

          // Update analytics
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
              'event': 'gtm.click',
              'customerData': {
                  'amount': orderTotal
              }
          });

          // Navigate to quiz
          navigate('../offer-v0', {
              replace: true,
              state: {buyerId}
          });

        } catch(error){
          setErrorMessage(error.response?.data?.error?.message || 'An error occured. Please try again.');
          setIsProcessing(false)
        }
        // else {
        //     // data here is data value part of response from backend api 
        //     try {
        //         const {data}  = await axios.post(serverSideBaseAPI + apiEndpoint, 
        //             {
        //                 name, 
        //                 email,
        //                 payment_method: paymentMethod.paymentMethod.id,
        //                 sub_price: subPriceState,
        //                 // orderTotal: orderTotal, 
        //                 order_items: bumpItems
        //             });
    
        //         console.log('After sub Attemt ', data)
                
        //         // Handle Order bump single charge 
        //         const {client_secret} = data;
        //         const {paymentIntent} = await stripe.confirmCardPayment(client_secret);
        //         console.log('Payment Intent', paymentIntent)
        //         if (paymentIntent.status === "succeeded"){

        //             const buyerId = randomString(12, Chars);

        //             // const mealplanIdLocalStorage = localStorage.getItem('mealplanId');
        //             // const mealplanId = mealplanIdLocalStorage.replace(/^"(.*)"$/, '$1');
                    
        //             const BuyerDetails = {
        //                 name,
        //                 email,
        //                 amount: paymentIntent.amount,
        //                 created: paymentIntent.created,
        //                 intentId: paymentIntent.id,
        //                 orderBump: upsellOneIsAdded,
        //                 optinId,
        //                 buyerId,
        //                 beehiivSubId,
        //                 mealplanUpsell: false,
        //                 subscription: true,
        //                 subscriptionId: data.subscription,
        //                 customerId: data.customer
        //             }
        //             console.log(BuyerDetails)
        //             dispatch(newBuyer(BuyerDetails))
        //             setPurchase(true)
        //             // dispatch(updateMealplanRecord({buyerId, mealplanId}))
        //             console.log('Order Total', orderTotal)

        //             // Add these trial subscribers to Beehiive automation
        //             const beehiiveApiCallUrl = beehiiveEndPointUrl+'publications/'+beehiivePubId+'/automations/'+beehiiveAutId+'/journeys';
        //             console.log(beehiiveApiCallUrl)
        //             const beehiiveSubData = {
        //               'email': email,
        //               'subscription_id': beehiivSubId
        //             }
        //             try {
        //               const beehiiveAutomation = await axios.post(beehiiveApiCallUrl, beehiiveSubData)
        //               console.log(beehiiveAutomation)
        //             } catch (error){
        //               console.error('Beehiive automation error:', error);
        //             }

        //             // Add final purchase amount to dataLayer
        //             window.dataLayer = window.dataLayer || [];
        //             window.dataLayer.push({
        //               'event': 'gtm.click',
        //               'customerData': {
        //                 'amount': orderTotal
        //               }
        //             })

        //             navigate('../jumptoquiz', {
        //               replace: true,
        //               state: {buyerId}
        //             })
        //         }

        //     } catch (error) {
        //         setErrorMessage(error.response.data.error.message)
        //         setIsProcessing(false)
        //     }
        // }
    }     
  


    const iframeStyles = {
        base: {
          color: "#32325",
          fontSize: "18px",
          iconColor: "#32325",
          "::placeholder": {
            color: "#32325"
          }
        },
        invalid: {
            iconColor: '#F51720',
            color: '#F51720',
          },
        complete: {
          iconColor: "green",
          color: "green"
        }
    };

    const cardElementOptions = {
        iconStyle: "solid",
        style: iframeStyles,
        hidePostalCode: true
    };

    // On purchase success navigate to desired page
    // if (purchase) {
    //     return <Navigate to={{pathname: '/jumptoquiz'}}/>
    // }

    return(
        <>
        <form onSubmit={handleSubmit}>
        <div className="space-y-4 mt-8 ">
            <div className='border p-5 bg-gray-100'>
              <div className="flex justify-between mb-1">
                <div>
                    <label className="block text-md font-bold text-gray-700">
                        Credit Card Number*
                    </label>
                </div>
                <div>
                    <label className="block text-md font-bold text-gray-700">
                        Exp date* | CVC*
                    </label>
                </div>
              </div>
            {/* <label className="block text-md font-bold text-gray-700 mb-1">
                Credit Card Number*
                </label> */}
              <CardFormContainer>
                  <CardElement options={cardElementOptions}/>
              </CardFormContainer>
              { errorMessage ? <p className="text-red-600 font-semibold text-base">{errorMessage}</p> : ''}
            </div>


        {/* 1st Upsell  Offer */}
          <div className="w-full bg-gray-100 border-2 border-gray-200 p-5 mt-8">
            <div className="flex flex-col sm:flex-row items-start gap-6">
              {/* Left Column - Image */}
              <div className="w-full sm:w-1/6">
                <div className="-mt-2">
                  <img
                    src={HackFitness}
                    alt="Hacking Fitness 101"
                    className="h-[220px] object-contain"
                  />
                </div>
              </div>

              {/* Center Column - Text Content */}
              <div className="flex-1">
                <h2 className="text-lg lg:text-xl font-bold text-gray-950 mb-1">
                  Special One Time Offer:
                </h2>
                <h3 className="capitalize text-lg lg:text-xl font-bold text-green-800 mb-2">
                  New Book reveals surprising discoveries in weight-loss science!
                </h3>
                <p className="text-lg lg:text-xl text-gray-700">
                  <span className='font-semibold'>DON’T DO A SINGLE SIT-UP </span>and 
                  <span className='font-semibold'> Lose as much as 3 to 5 inches off your waistline in one month</span>. 
                  You will probably lose more inches each week than if you faithfully did hundreds sit-ups every day. 
                  It's normally <strong className='decoration-red-600 line-through'>$39.98</strong>, but you can have it today for {' '}
                  <strong className='underline decoration-green-600'>${hfPriceState}</strong>.<br/> 
                  <span className='font-semibold'> THIS OFFER WILL NOT BE REPEATED</span>
                  {/* <a href="#show-bump-content-1" className="text-[#4775B9] underline ml-1">
                    ... more
                  </a> */}
                </p>
              </div>

              {/* Right Column - Button */}
              <div className="w-full sm:w-1/6 flex justify-end">
                {!upsellOneIsAdded ? (
                  <button
                    type="button"
                    id="add-hf"
                    onClick={() => setUpsellOneIsAdded(true)}
                    className="flex border-2 rounded-lg border-gray-400 items-center justify-center px-6 py-2 text-gray-700 text-base font-semibold hover:font-bold hover:text-gray-700 hover:border-gray-700 transition-colors"
                  >
                    <svg 
                      viewBox="0 0 24 24" 
                      className="w-5 h-5 mr-2" 
                      fill="currentColor"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
                    </svg>
                    ADD
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setUpsellOneIsAdded(false)}
                    className="flex items-center justify-center px-6 py-2 text-green-700 bg-green-200 rounded-md text-base"
                  >
                    <svg 
                      viewBox="0 0 24 24" 
                      className="w-5 h-5 mr-2" 
                      fill="currentColor"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
                    </svg>
                    ADDED
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* 2nd Upsell  Offer */}
          <div className="w-full bg-gray-100 border-2 border-gray-200 p-5 mt-8">
            <div className="flex flex-col sm:flex-row items-start gap-6">
              {/* Left Column - Image */}
              <div className="w-full sm:w-1/6">
                <div className="-mt-2">
                  <img
                    src={BulletProofKeto}
                    alt="Hacking Fitness 101"
                    className="h-[220px] object-contain"
                  />
                </div>
              </div>

              {/* Center Column - Text Content */}
              <div className="flex-1">
                <h2 className="text-lg lg:text-xl font-bold text-gray-950 mb-1">
                  Exclusive Upgrade:
                </h2>
                <h3 className="capitalize text-lg lg:text-xl font-bold text-green-800 mb-2">
                Turn Your Body into a Fat-Torching Machine And <br/>QUADRUPLE THE FAT BURN!
                </h3>
                <p className="text-lg lg:text-xl text-gray-700">
                  <span className='font-semibold'>Everything you've been told about losing weight is wrong!</span> Discover the secret code to effortless, life-long weight loss success.  
                  Turn the switch on your body's natural ability to melt away unwanted fat without willpower, calorie counting, yo-yo dieting and unnecessary exercise. Regular price of The Bulletproof Keto Diet course is {' '}<strong className='decoration-red-600 line-through'>$89.99</strong>, but with this ONE TIME ONLY special offer you can get it for just {' '} 
                  <strong className='underline decoration-green-600'> ${bpkPriceState}</strong>. 
                  <span className='font-semibold'> THIS OFFER WILL NOT BE REPEATED</span>
                  {/* <a href="#show-bump-content-1" className="text-[#4775B9] underline ml-1">
                    ... more
                  </a> */}
                </p>
              </div>

              {/* Right Column - Button */}
              <div className="w-full sm:w-1/6 flex justify-end">
                {!upsellTwoIsAdded ? (
                  <button
                    type="button"
                    id="add-bpk"
                    onClick={() => setUpsellTwoIsAdded(true)}
                    className="flex border-2 rounded-lg border-gray-400 items-center justify-center px-6 py-2 text-gray-700 text-base  font-semibold hover:font-bold hover:text-gray-700 hover:border-gray-700 transition-colors"
                  >
                    <svg 
                      viewBox="0 0 24 24" 
                      className="w-5 h-5 mr-2" 
                      fill="currentColor"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
                    </svg>
                    ADD
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setUpsellTwoIsAdded(false)}
                    className="flex items-center justify-center px-6 py-2 text-green-700 bg-green-200 rounded-md text-base"
                  >
                    <svg 
                      viewBox="0 0 24 24" 
                      className="w-5 h-5 mr-2" 
                      fill="currentColor"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
                    </svg>
                    ADDED
                  </button>
                )}
              </div>
            </div>
          </div>

      {/* Order Summary */}
        <div className="mb-6 mt-6">
          <div className="flex justify-between items-center mb-4 border-b pb-2">
            <div className="text-gray-700 font-bold">Item</div>
            <div className="text-gray-700 font-bold">Price</div>
          </div>
          <div className="flex font-semibold justify-between items-center mb-2">
            <div className='lg:text-lg'>Eat To Burn book</div>
            <div className='lg:text-lg'><span className='decoration-4 line-through decoration-red-600'>$37.00</span>
             <strong> FREE</strong>
            </div>
          </div>
          <div className="flex font-semibold justify-between items-center mb-2">
            <div className='lg:text-lg'>Eat To Burn Personal Plan</div>
            <div className='lg:text-lg'>Monthly - $0.00 for 1st month + ${subPriceState}/mo</div>
          </div>
          {/* <div className="flex justify-between items-center mb-2 text-gray-600 text-sm">
            <div>Taxes</div>
            <div>$0.70</div>
          </div> */}
          <div className="flex justify-between items-center font-bold border-t pt-2">
            <div className='lg:text-lg'>Today Total:</div>
            <div className='lg:text-lg'>${orderTotal}</div>
          </div>
        </div>
        {/* Disclaimer and Consent check box */}
        <div className="relative" data-page-element="Checkbox/V1" data-checked="false">
          <label className="flex items-start cursor-pointer group">
            <input 
              type="checkbox" 
              name="terms_checked" 
              className="sr-only peer" 
              value="true"
            />
            
            {/* Custom checkbox */}
            <div 
              className={`relative flex-shrink-0 w-5 h-5 mt-1 font-bold border border-gray-700 rounded bg-white cursor-pointer ${isChecked ? 'border-gray-700' : ''}`}
              onClick={()=>{setIsChecked(!isChecked)}}
              >
            <div className={`absolute inset-0 ${isChecked ? 'flex' : 'hidden'} items-center justify-center`}>
                <svg 
                  className="w-5 h-5 text-gray-700 stroke-2" 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path 
                    fillRule="evenodd" 
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" 
                    clipRule="evenodd" 
                  />
                </svg>
              </div>
            </div>

            {/* Text content */}
            <span className={`ml-3 text-md font-medium  first-letter
               ${errorMessage && !isChecked ? 'text-red-600' : 'text-gray-600'} `}>
              <p>
                <span>I agree that I subscribe to ${subPriceState}/month payment following 30-day trial. You can cancel anytime through your dashboard or by emailing support@eattoburn.com. I also agree to the </span>
                <a 
                  href="/legal/termsofuse" 
                  target="_blank"
                  className="text-blue-600 hover:underline"
                >
                  Terms of Service
                </a>
                <span> and </span>
                <a 
                  href="/legal/privacypolicy" 
                  target="_blank"
                  className="text-blue-600 hover:underline"
                >
                  Privacy Policy
                </a>
                <span>.</span>
              </p>
            </span>
          </label>
          
          {/* Error container */}
          <div data-error-container="true"></div>
        </div>
        <button
            type="submit"
            disabled={isProcessing || !stripe || !elements}
            className="block mx-auto w-5/6 bg-green-500 text-white py-6 rounded-lg font-bold hover:bg-green-600 transition-colors mt-6"
          >
            <span className="text-3xl">
              {isProcessing ? 'Processing' :  'Ship My Book NOW!!!'}
            </span>
        </button>
        { errorMessage ? <p className="text-red-600 font-semibold text-lg md:text-xl">{errorMessage}</p> : ''}  
          <p className="text-center text-sm text-gray-500 mt-4">
            *Eat To Burn retails for $24.95, but we bought it for you as our thank you for giving Eat To Burn Personal plan a   try.  Your information is secure and will not be shared.
          </p>

          {/* Trust Badges */}
          <div className="flex justify-center space-x-8 mt-8">
            <div className="text-center">
              <div className="h-12 w-12 mx-auto mb-2">
                <svg viewBox="0 0 24 24" className="w-full h-full text-green-700">
                  <path fill="currentColor" d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z"/>
                </svg>
              </div>
              <span className="text-sm text-green-700">Secure Checkout</span>
            </div>

            {/* <div className="text-center">
              <div className="h-12 w-12 mx-auto mb-2">
                <svg viewBox="0 0 24 24" className="w-full h-full text-gray-700">
                  <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                </svg>
              </div>
              <span className="text-sm text-gray-600">Money-Back Guarantee</span>
            </div>

            <div className="text-center">
              <div className="h-12 w-12 mx-auto mb-2">
                <svg viewBox="0 0 24 24" className="w-full h-full text-gray-700">
                  <path fill="currentColor" d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"/>
                </svg>
              </div>
              <span className="text-sm text-gray-600">100,000+ Copies Sold</span>
            </div> */}
          </div>
        </div>
        </form>
        </>
    )
}

export default PaymentForm