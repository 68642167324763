import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import mitolynScreen from '../../../assets/img/affiliate/mitolyn/mitolyn-vid-screen.png'

export default function FirstUpsellAffiliatePresellVariant0() {

  const hopLink = 'https://9b67doqo28s9s877pdrj8c2w7l.hop.clickbank.net/?&traffic_source=newsletter&traffic_type=funnelOffer';

  return (
    // <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
    //   <div className="absolute inset-0 -z-10 overflow-hidden">
    //     <svg
    //       aria-hidden="true"
    //       className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
    //     >
    //       <defs>
    //         <pattern
    //           x="50%"
    //           y={-1}
    //           id="e813992c-7d03-4cc4-a2bd-151760b470a0"
    //           width={200}
    //           height={200}
    //           patternUnits="userSpaceOnUse"
    //         >
    //           <path d="M100 200V.5M.5 .5H200" fill="none" />
    //         </pattern>
    //       </defs>
    //       <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
    //         <path
    //           d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
    //           strokeWidth={0}
    //         />
    //       </svg>
    //       <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
    //     </svg>
    //   </div>
    //   <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
    //     <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
    //       <div className="lg:pr-4">
    //         <div className="lg:max-w-lg">
    //           <p className="text-xl/7 pb-4 font-semibold text-blue-800">Thank you for taking advantage of our special offer!</p>
    //           <p className="mt-6 text-xl/8 text-gray-700">We have received your order and email with the link to your dashboard page is on its way as we speak!</p>
    //           <p className="mt-6 text-xl/8 text-gray-700">In the mean while, please review this brief offer and get your additional bonus gift exclusive for new subscribers.</p>
    //           <p className="text-4xl/7 py-4 font-semibold text-indigo-600">Your Next Steps</p>
    //           <h1 className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-red-700 sm:text-4xl">
    //            How To Unlock Your Metabolic Power
    //           </h1>
    //           <p className="mt-6 text-xl/8 text-gray-700">
    //           Because we can tell you’re committed to your health, you also qualify to receive up to 180 days supply of 
    //           the only product in the world with a proprietary blend of 6 exotic nutrients and plants designed to 
    //           support <b>healthy mitochondria levels</b> and <b>vaporize fat cells</b>!
    //           </p>
    //           <p className="mt-6 text-xl/8 text-gray-700">
    //           This <b>Purple Peel Exploit </b>ships from a separate warehouse and you may only claim it from this special offer. So please keep reading.
    //           </p>
    //           <p className="mt-6 text-xl/8 text-gray-700">
    //           First, here’s a little background . . .
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="-ml-[64px] -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
    //         <a href="https://mitolyn.com/science/?affiliate=testigncb1">
    //         <img
    //         alt=""
    //         // src="https://mitolyn.com/assets/images/3-blank.png"
    //         src={mitolynScreen}
    //         className="w-[24rem] max-w-none rounded-xl bg-gray-200 shadow-xl ring-1 ring-gray-200/10 sm:w-[40rem]"
    //         />
            
    //         <h2 className='text-pretty mt-2 text-xl font-semibold tracking-tight text-blue-900 sm:w-[40rem] sm:text-4xl'>
    //           Watch the Purple Peel Exploit Now And Turbocharge Your Calorie Burn.
    //         </h2></a>
    //     </div>
    //     <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
    //       <div className="lg:pr-4">
    //         <div className="max-w-xl text-base/7 text-gray-700 lg:max-w-lg">
    //           {/* <p className="mt-6 text-xl/8 text-gray-700">
    //           Four years ago, a US scientist made a shocking claim.

    //           He said he could triple calorie burning with a strange purple peel.

    //           They laughed. They called him crazy. Sponsors pulled funding.

    //           He nearly went bankrupt. His marriage was on the brink of collapse.

    //           But he didn’t stop.

    //           For years, he worked tirelessly in his lab, pouring everything he had into proving the world wrong.

    //           And then it happened. The fat-torching purple peel exploit was born — a fat melting breakthrough so powerful, it’s gone viral on the internet.

    //           This simple, natural trick turbocharges calorie burning 3X faster — without diets or exercise.

    //           Millions have already watched his discovery, and now you can see it for yourself.

    //           ==> Watch the Purple Peel Exploit Now And Turbocharge Your Calorie Burn.

    //           His journey risked everything. but it could change everything for you.

    //           Try it for yourself tonight.

    //           </p> */}
    //           <ul role="list" className="text-xl space-y-8 text-gray-600">
    //             <li className="flex gap-x-3 ">
    //               {/* <CloudArrowUpIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" /> */}
    //               <span>
    //                 {/* <strong className="font-semibold text-gray-900">Push to deploy.</strong>   */}
    //                 Four years ago, a US scientist made a shocking claim.

    //                 He said he could <span className='bg-yellow-200 underline decoration-2 decoration-purple-800'>triple calorie burning </span>with a strange purple peel.

    //                 They laughed. They called him crazy. Sponsors pulled funding.

    //                 He nearly went bankrupt. His marriage was on the brink of collapse.

    //                 But he didn’t stop.
    //               </span>
    //             </li>
    //             <li className="flex gap-x-3">
    //               {/* <LockClosedIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" /> */}
    //               <span>
    //                 {/* <strong className="font-semibold text-gray-900">SSL certificates.</strong>  */}
    //                 For years, he worked tirelessly in his lab, pouring everything he had into proving the world wrong. 
    //                 And then it happened. The fat-torching purple peel exploit was born — a fat melting breakthrough so powerful, it’s gone viral on the internet. 
                
    //               </span>
    //             </li>
    //             <li className="flex gap-x-3">
    //               {/* <ServerIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600" /> */}
    //               <span>
    //                 {/* <strong className="font-semibold text-gray-900">Database backups.</strong>  */}
    //                 This simple, <span className='bg-yellow-200 underline decoration-2 decoration-purple-800'> natural trick turbocharges calorie burning 3X faster</span> — without diets or exercise. Millions have already watched his discovery, and now you can see it for yourself.
    //               </span>
    //             </li>

    //           </ul>
    //           {/* <p className="mt-8">
    //             Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor
    //             fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac
    //             adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
    //           </p> */}
    //           <a 
    //           href="https://mitolyn.com/science/?affiliate=testigncb1"
    //           className="mt-20 underline text-2xl font-bold tracking-tight text-blue-600">
    //             ==> Watch the Purple Peel Exploit Now And Turbocharge Your Calorie Burn.</a>
    //           <p className="mt-6 text-xl">
    //           His journey risked everything. but it could change everything for you. Try it for yourself tonight.
    //           </p>
    //           <p className="mt-6 text-xl">
    //           For complete details on claiming your own supply of this 3-times-faster calorie burning formula directly from US manufacturer, just click the big orange button below right now.
    //           </p>
    //           <p className="my-6 text-xl">
    //           You’ve already taken a great first step towards better health. 
    //           Please enjoy this opportunity to try out this <b>Amazing Purple Peel Exploit </b>
    //           with this special offer, good while supplies last.
    //           </p>
    //           <a 
    //             className="rounded-md bg-orange-500 px-5 py-4 text-lg sm:text-2xl font-semibold text-gray-100 shadow-sm hover:bg-orange-600"
    //             href='https://mitolyn.com/science/?affiliate=testigncb1'>
    //           CLICK HERE TO GET YOUR BOTTLE
    //           </a>

    //         </div>

    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
    <div className="absolute inset-0 -z-10 overflow-hidden">
      <svg
        aria-hidden="true"
        className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
      >
        <defs>
          <pattern
            x="50%"
            y={-1}
            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
            width={200}
            height={200}
            patternUnits="userSpaceOnUse"
          >
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
      </svg>
    </div>
    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
      <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div className="lg:pr-4">
          <div className="lg:max-w-lg">
            <p className="text-lg/7 lg:text-xl/7 pb-4 font-semibold text-blue-800">Thank you for taking advantage of our special offer!</p>
            <p className="mt-6 text-lg/7 lg:text-xl/8 text-gray-700">We have received your order and email with the link to your dashboard page is on its way as we speak!</p>
            <p className="mt-6 text-lg/7 lg:text-xl/8 text-gray-700">In the mean while, please review this brief offer and get your additional bonus gift exclusive for new subscribers.</p>
          </div>
        </div>
      </div>

      <div className="-ml-[64px] -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
        <a href={hopLink}>
          <img
            alt=""
            src={mitolynScreen}
            className="w-[24rem] max-w-none rounded-xl bg-gray-200 shadow-xl ring-1 ring-gray-200/10 sm:w-[40rem]"
          />
          <h2 className='text-pretty mt-2 text-xl font-semibold tracking-tight text-blue-900 sm:w-[40rem] sm:text-4xl'>
            Watch the Purple Peel Exploit Now And Turbocharge Your Calorie Burn.
          </h2>
        </a>
      </div>

      <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div className="lg:pr-4">
          <div className="lg:max-w-lg">
            <p className="text-4xl/7 py-4 -mt-20 sm:-mt-10 font-semibold text-indigo-600">Your Next Steps</p>
            <h1 className="mt-2 text-pretty text-3xl font-semibold tracking-tight text-red-700 sm:text-4xl">
              How To Unlock Your Metabolic Power
            </h1>
            <p className="mt-6 text-lg/7 lg:text-xl/8 text-gray-700">
              Because we can tell you're committed to your health, you also qualify to receive up to 180 days supply of 
              the only product in the world with a proprietary blend of 6 exotic nutrients and plants designed to 
              support <b>healthy mitochondria levels</b> and <b>vaporize fat cells</b>!
            </p>
            <p className="mt-6 text-lg/7 lg:text-xl/8 text-gray-700">
              This <b>Purple Peel Exploit </b>ships from a separate warehouse and you may only claim it from this special offer. So please keep reading.
            </p>
            <p className="mt-6 text-lg/7 lg:text-xl/8 text-gray-700">
              First, here's a little background . . .
            </p>
          </div>
        </div>
      </div>

      <div className="lg:col-span-2 lg:col-start-1 lg:row-start-3 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        {/* Rest of the content remains the same */}
        <div className="lg:pr-4">
          <div className="max-w-xl text-gray-700 lg:max-w-lg">
            <ul role="list" className="text-lg/7 lg:text-xl -mt-10 space-y-8 text-gray-600">
              {/* List items remain the same */}
              <li className="flex gap-x-3 ">
                <span>
                  Four years ago, a US scientist made a shocking claim.

                  He said he could <span className='bg-yellow-200 underline decoration-2 decoration-purple-800'>triple calorie burning </span>with a strange purple peel.

                  They laughed. They called him crazy. Sponsors pulled funding.

                  He nearly went bankrupt. His marriage was on the brink of collapse.

                  But he didn't stop.
                </span>
              </li>
              <li className="flex gap-x-3">
                <span>
                  For years, he worked tirelessly in his lab, pouring everything he had into proving the world wrong. 
                  And then it happened. The fat-torching purple peel exploit was born — a fat melting breakthrough so powerful, it's gone viral on the internet. 
                </span>
              </li>
              <li className="flex gap-x-3">
                <span>
                  This simple, <span className='bg-yellow-200 underline decoration-2 decoration-purple-800'> natural trick turbocharges calorie burning 3X faster</span> — without diets or exercise. Millions have already watched his discovery, and now you can see it for yourself.
                </span>
              </li>
            </ul>
            
            <a 
              href={hopLink}
              className="mt-20 underline text-2xl font-bold tracking-tight text-blue-600">
              ==> Watch the Purple Peel Exploit Now And Turbocharge Your Calorie Burn.
            </a>
            
            <p className="mt-6 text-lg/7 lg:text-xl">
              His journey risked everything. but it could change everything for you. Try it for yourself tonight.
            </p>
            
            <p className="mt-6 text-lg/7 lg:text-xl">
              For complete details on claiming your own supply of this 3-times-faster calorie burning formula directly from US manufacturer, just click the big orange button below right now.
            </p>
            
            <p className="my-6 text-lg/7 lg:text-xl">
              You've already taken a great first step towards better health. 
              Please enjoy this opportunity to try out this <b>Amazing Purple Peel Exploit </b>
              with this special offer, good while supplies last.
            </p>
            
            <a 
              className="rounded-md bg-orange-500 px-5 py-4 text-lg sm:text-2xl font-semibold text-gray-100 shadow-sm hover:bg-orange-600"
              href={hopLink}>
              CLICK HERE TO GET YOUR BOTTLE
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
