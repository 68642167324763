const featuredTestimonial = {
    body: 'I’m 69 and keto made it possible for me to climb Mt Fuji',
    author: {
      // name: 'Brenna Goyette',
      handle: 'u/whatthehellisketosma',
      imageUrl:
        'https://i.redd.it/snoovatar/avatars/nftv2_bmZ0X2VpcDE1NToxMzdfZWI5NTlhNzE1ZGZmZmU2ZjgyZjQ2MDU1MzM5ODJjNDg1OWNiMTRmZV8zMTY4ODQ0_rare_860b1df5-0c19-4360-969e-dc80130d6095.png',
    },
  }
  const testimonials = [
    [
      [
        {
          body: 'I’m almost one year in! Heeeeeeeeeey less chins, more jaw line. Woooop!',
          author: {
            // name: 'Leslie Alexander',
            handle: 'u/noshurtafi',
            imageUrl:
              'https://www.redditstatic.com/avatars/defaults/v2/avatar_default_0.png'
          },
        },
        {
          body: "I only lost 35 pounds. I'm still fat, but I feel so damn healthy. I sleep better, when I'm awake I'm actually awake. I get stuff done. Being alive feels good.",
          author: {
            // name: 'Leslie Alexander',
            handle: 'noshurtafi',
            imageUrl:
              'https://www.redditstatic.com/avatars/defaults/v2/avatar_default_3.png'
          },
        },
        {
          body: "I think I can honestly say that I've gained a life. I went from having trouble fitting into an airline seats, to as of recently being able to jump out of one to skydive.",
          author: {
            // name: 'Leslie Alexander',
            handle: 'u/holamynameismike182',
            imageUrl:
              'https://www.redditstatic.com/avatars/defaults/v2/avatar_default_7.png'
          },
        },
        // More testimonials...
      ],
      [
        {
          body: "I did it…I reached my first weight loss goal! I haven't been under 200 pounds in at least a couple years, but as of today, I am at 199! 9 weeks and over 20 pounds lost…",
          author: {
            // name: 'Lindsay Walton',
            handle: 'u/Sassypants_me',
            imageUrl:
              'https://i.redd.it/snoovatar/avatars/nftv2_bmZ0X2VpcDE1NToxMzdfZTY0YmI3ZGQwYTEzYWY2MjJlMGRlNjQ1NmQxYmJhZGM4ZDFlYzA5ZF81NjQx_rare_394326ad-ff00-4f05-b6b4-9f0afa5d3410.png'
          },
        },
        {
          body: "The constant pain and depression is gone.",
          author: {
            // name: 'Lindsay Walton',
            handle: 'Sassypants_me',
            imageUrl:
              'https://i.redd.it/snoovatar/avatars/nftv2_bmZ0X2VpcDE1NToxMzdfZTY0YmI3ZGQwYTEzYWY2MjJlMGRlNjQ1NmQxYmJhZGM4ZDFlYzA5ZF81NjQx_rare_394326ad-ff00-4f05-b6b4-9f0afa5d3410.png'
          },
        },
        {
          body: "I'm down about 85lbs! Size 28 to size 18!",
          author: {
            // name: 'Lindsay Walton',
            handle: 'u/Eriseurydice',
            imageUrl:
              'https://i.redd.it/snoovatar/avatars/nftv2_bmZ0X2VpcDE1NToxMzdfNmFjYjhmYjgyODgwZDM5YzJiODQ0NmY4Nzc4YTE0ZDM0ZWU2Y2ZiN18xNzE4OTY_rare_fea9c09b-a332-4c2f-94f7-58b0c3210f72.png'
          },
        },
        // More testimonials...
      ],
    ],
    [
      [
        {
          body: "I'm a loser (-325 lbs) ... And couldn't be happier",
          author: {
            // name: 'Tom Cook',
            handle: 'u/holamynameismike182',
            imageUrl:
              'https://www.redditstatic.com/avatars/defaults/v2/avatar_default_4.png',
          },
        },
        {
          body: "A buddy of mine at work used to call me Big Bear but not since I lost 58 pounds in the last 3 1/2 months. He said he'll have to start calling me Medium or Jr. Bear.",
          author: {
            // name: 'Tom Cook',
            handle: 'Brian',
            imageUrl:
              'https://www.redditstatic.com/avatars/defaults/v2/avatar_default_5.png',
          },
        },
        // More testimonials...
      ],
      [
        {
          body: "...I'm about 65-70 pounds down. Still have around 50 to lose. Favorite keto meal is anything with zoodles and cream sauce. Also, bacon.",
          author: {
            // name: 'Leonard Krasner',
            handle: 'leonardkrasner',
            imageUrl:
              'https://www.redditstatic.com/avatars/defaults/v2/avatar_default_2.png',
          },
        },
        {
          body: "Feeling Soo Much Better! It's like my body was begging me to get those pounds off!! My stomach was my biggest problem area so now that it's smaller I look like a new person!",
          author: {
            // name: 'Leonard Krasner',
            handle: 'u/Monique050406',
            imageUrl:
              'https://www.redditstatic.com/avatars/defaults/v2/avatar_default_1.png',
          },
        },
        // More testimonials...
      ],
    ],
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function KetoWins() {
    return (
      <div className="relative isolate bg-white pb-32 pt-8 sm:pt-12">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
          />
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
          />
        </div>
        
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="mx-auto max-w-4xl py-3 text-center">
            {/* <h2 className="text-xl/7 lg:text-3xl font-semibold text-indigo-600"> */}
            <div className="mt-4 pb-12 sm:pb-16">
              <div className="relative">
                <div className="absolute inset-0 h-1/2" />
                <div className="relative mx-auto max-w-16xl px-2 lg:px-8">
                  <div className="mx-auto max-w-8xl">
                    <dl className="rounded-2xl bg-yellow-100 shadow-2xl">
                      <div className="flex flex-col border rounded-xl border-gray-200 text-center py-3 sm:border-2 sm:border-r">
                        {/* <dt className="order-2 mt-2 text-lg/6 font-medium text-gray-500">Pepperoni</dt> */}
                        <dd className="order-1 text-2xl lg:text-3xl font-medium tracking-tight text-gray-900">
                        The social media buzz around these almost magic <span className="font-black">health transformations</span> is astounding!
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            {/* <h2 className="mx-auto max-w-3xl text-center text-xl/8 lg:text-2xl lg:mt-12">
              The social media buzz around these almost magic health transformations is astounding.
            </h2> */}
            {/* <p className="mt-2 mx-auto max-w-3xl text-balance text-2xl font-semibold tracking-tight text-gray-900 lg:text-3xl">
            Look to what people who’ve used keto to <span className="font-black"> shed pounds of fat</span> have said about their success on ketogenic diet on popular social media platform Reddit:
            </p> */}
            <p className="mt-2 mx-auto max-w-3xl text-balance text-2xl font-semibold tracking-tight text-gray-900 lg:text-3xl">
            Just look at all the raving comments on Reddit<br/> (popular social media site): 
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm/6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
            <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
              <blockquote className="p-6 text-lg font-semibold tracking-tight text-gray-900 sm:p-12 sm:text-xl/8">
                <p>{`“${featuredTestimonial.body}”`}</p>
              </blockquote>
              <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                <img
                  alt=""
                  src={featuredTestimonial.author.imageUrl}
                  className="h-10 w-10 flex-none rounded-full bg-gray-50"
                />
                <div className="flex-auto">
                  <div className="font-semibold">{featuredTestimonial.author.name}</div>
                  <div className="font-semibold text-gray-600">{`@${featuredTestimonial.author.handle}`}</div>
                </div>
                <img alt="" src={featuredTestimonial.author.logoUrl} className="h-10 w-auto flex-none" />
              </figcaption>
            </figure>
            {testimonials.map((columnGroup, columnGroupIdx) => (
              <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                {columnGroup.map((column, columnIdx) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) ||
                        (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                        ? 'xl:row-span-2'
                        : 'xl:row-start-1',
                      'space-y-8',
                    )}
                  >
                    {column.map((testimonial) => (
                      <figure
                        key={testimonial.author.handle}
                        className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                      >
                        <blockquote className="text-gray-900 text-lg lg:text-xl">
                          <p>{`“${testimonial.body}”`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          <img alt="" src={testimonial.author.imageUrl} className="h-10 w-10 rounded-full bg-gray-50" />
                          <div>
                            <div className="font-semibold">{testimonial.author.name}</div>
                            <div className="font-semibold text-gray-600">{`@${testimonial.author.handle}`}</div>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  