export default function ActionButton({ onOpenModal }) {

  console.log('ActionButton rendered:', {
    hasOnOpenModal: Boolean(onOpenModal),
    isFunction: typeof onOpenModal === 'function',
  });
  
  // Add prop validation
  if (!onOpenModal || typeof onOpenModal !== 'function') {
    console.warn('ActionButton: onOpenModal prop is required and must be a function');
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    onOpenModal();
  };

  return (
    <div className="w-full flex items-center justify-center">
      <button
        onClick={handleClick}
        className="relative max-w-5xl shadow-xl flex mt-2 sm:mt-6 px-10 sm:px-8 py-6 rounded-lg bg-green-500 text-xl sm:text-3xl font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-green-400 focus:z-10"
      >
        <span className="mx-auto text-center uppercase">
          YES! Reserve My Free Copy <span className="capitalize">(Plus <u>Special Bonus</u>)</span> Now!
        </span>
      </button>
    </div>
  );
}