import React, {useEffect} from "react";
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { fullBuyerRecord } from "../features/buyer/buyerSlice";
// import {HeroSection, CallToAction} from '../components/jumptoquiz'
import HeroSection from '../components/jumptoquiz/HeroSection';
import CallToAction from "../components/jumptoquiz/CallToAction";
import FeaturesSection from "../components/jumptoquiz/FeatureSection";
import CallToActionBottom from "../components/jumptoquiz/CallToActionBottom";
import Footer from "../components/newsletter/thankyou/v1Footer";

export default function QuizJump(){

  const location = useLocation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
    
    useEffect(() => {

      // Check both location state and URL query parameters for buyerId
      const buyerIdFromState = location.state?.buyerId;
      const buyerIdFromUrl = searchParams.get('buyerid');
      const buyerId = buyerIdFromState || buyerIdFromUrl;
        // Check if user came from payment
        if (buyerId) {
            // Do something specific for users who just completed payment
            // For example, show a success message or specific content
            // const buyerId = location.state.buyerId
            // console.log(buyerId)
            dispatch(fullBuyerRecord(buyerId))
        }
    }, []);

  return(
    <>
    <HeroSection/>
    <CallToAction />
    <FeaturesSection />
    <CallToActionBottom />
    <Footer />
    </>
  )
}