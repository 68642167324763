import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { register, reset } from '../features/auth/authSlice';
import { VscFlame } from "react-icons/vsc";
import { RotatingLines } from  'react-loader-spinner'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';


export default function Register() {

  const [isNameValid, setNameValid] = useState(true);
  const [isEmailValid, setEmailValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [isConfirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [agree, setAgree] = useState(false)
  const [isAgreeValid, setAgreeValid] = useState(true)

  const [registerError, setRegisterError] = useState('');
  const [ registerFail, setRegisterFail] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // get buyerId value saved in local storage
  // const buyer = JSON.parse(localStorage.getItem('buyerId'));
  // const optinId = JSON.parse(localStorage.getItem('optin'));
  const buyer = useSelector(state => 
    state.buyer?.data?.newBuyer?.buyerId ||
    state.buyer?.data?.buyerId ||
    null);
  
  const optinId = useSelector(state => 
    state.optin?.data?.newNewOptinRecord?.optinId ||
    state.buyer?.data?.optinId || 
    null);
  // console.log(buyer)

  const storedEmail = useSelector(state => 
    state.buyer?.data?.newBuyer?.email ||
    state.buyer?.data?.email ||
    ''); // Adjust this path according to your Redux state structure
  // console.log(storedEmail)
  const storedName = useSelector(state => 
    state.buyer?.data?.newBuyer?.name ||
    state.buyer?.data?.name ||
    ''); // Adjust this path according to your Redux state structure
  // console.log(storedName)
  const [ formData, setFormData] = useState({
    name: storedName || '',
    email: storedEmail || '',
    password: '',
    password2: ''
  });

  const { name, email, password, password2 } = formData; 
  
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoadding, isError, isSuccess, message} = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if(isError){
      // toast.error(message)
      setRegisterFail(true);
      setRegisterError(message);
    }

    if(isSuccess || user){
      navigate('/home')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])  

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      name: storedName || '',
      email: storedEmail || ''
    }));
  }, [storedName, storedEmail]);

  const onChange = (e) => {
    setNameValid(true)
    setEmailValid(true)
    setPasswordValid(true)
    setConfirmPasswordValid(true)
    setAgreeValid(true)

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  };

  const onSubmit = (e) => {
    e.preventDefault()

    // if (password !== password2){
    //   setRegisterError("Passwords don't match")

    // } else {
      const userData = {
        name,
        email,
        password,
        password2,
        buyerId: buyer,
        optinId,
      }

      // Name validation function checks for characters only between 3 and 30 length
      const validateName = (name) => {
         //const namePattern = /^[A-Za-z]{3,30}$/;
        const namePattern = /^[a-zA-Z]+([ \-'`]{0,1}[a-zA-Z]+){0,2}[.]?$/;
        return namePattern.test(name);
      };

        // Email validation function
      const validateEmail = (email) => {
        // Regular expression for email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
      };

      // Password validation function
      const validatePassword = (password) => {
        // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return passwordPattern.test(password)
      }

      // Check password confirmation function
      const passwordConfirm  = (password, password2) => {
        const passwordsMatch = password === password2;
        return passwordsMatch;
      }

      if (userData.name.trim() === '' || !validateName(userData.name)) {
        setNameValid(false);
      } 

      if (userData.email.trim() === '' || !validateEmail(userData.email)) {
        setEmailValid(false);
      }

      if (userData.password.trim() === '' || !validatePassword(userData.password)){
        setPasswordValid(false)
      }

      if (userData.password2.trim() === '' || !passwordConfirm(userData.password, userData.password2)) {
        setConfirmPasswordValid(false)
      }

      if (!agree) {
        setAgreeValid(false)
      }

      if (userData.name.trim() !== '' && userData.email.trim() !== '' && 
          validateEmail(userData.email) && userData.password.trim() !== '' && 
          userData.password2.trim() !== '' && passwordConfirm(userData.password, userData.password2) && agree) {

        dispatch(register(userData))
      }
    // }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  
  return (
    <>
      <div 
        className="bg-gradient-to-b from-purple-400 to-white flex 
              min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md text-purple-700">
          <VscFlame className="mx-auto h-12 w-auto" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-purple-700">Create your account</h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={onSubmit} className="space-y-6" action="#" method="POST" noValidate>
              
            <div>
                <label htmlFor="name" className="block text-lg font-medium leading-6 text-gray-900">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    style={{fontSize: '1.25rem'}}
                    onChange={onChange}
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    autoComplete="name"
                    className={`block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                    ${isNameValid ? '' : 'ring-red-500 bg-red-200'}`}
                  />
                </div>
                { !isNameValid && 
                <p className="mt-2 text-md font-medium text-red-600" id="email-error">Please enter your name.</p> }
              </div>

              <div>
                <label htmlFor="email" className="block text-lg font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    style={{fontSize: '1.25rem'}}
                    onChange={onChange}
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    autoComplete="email"
                    className={`block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                    ${isEmailValid ? '' : 'ring-red-500 bg-red-200'}`}
                  />
                </div>
                { !isEmailValid && 
                <p className="mt-2 text-md font-medium text-red-600" id="email-error">Please enter valid email.</p> }
              </div>

              <div>
                <label htmlFor="password" className="block text-lg font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2 relative">
                  <input
                    style={{fontSize: '1.25rem'}}
                    onChange={onChange}
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    // type="password"
                    value={password}
                    autoComplete="current-password"
                    className={`block w-full pr-10 rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                    ${isPasswordValid ? '' : 'ring-red-500 bg-red-200'}`}
                   />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 focus:outline-none"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button>   
                </div>
                { !isPasswordValid && 
                <p className="mt-2 text-md font-medium text-red-600" id="email-error">Password has to be at least 8 characters long, contain one uppercase, one lowercase letter and at least one digit.</p> }
              </div>

              <div>
                <label htmlFor="password" className="block text-lg font-medium leading-6 text-gray-900">
                  Confirm Password
                </label>
                <div className="mt-2 relative">
                  <input
                    style={{fontSize: '1.25rem'}}
                    onChange={onChange}
                    id="password2"
                    name="password2"
                    type={showConfirmPassword ? "text" : "password"}
                    // type="password"
                    value={password2}
                    // autoComplete="current-password"
                    className={`block w-full pr-10 rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                    ${isConfirmPasswordValid ? '' : 'ring-red-500 bg-red-200'}`}
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 focus:outline-none"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <EyeSlashIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button> 
                </div>
                { !isConfirmPasswordValid && 
                <p className="mt-2 text-md font-medium text-red-600" id="email-error">Passwords don't match.</p> }
              </div>

              <div className="flex items-center justify-between">
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <input
                      id="agree"
                      name="agree"
                      type="checkbox"
                      checked={agree}
                      onChange={(e) => {setAgree(e.target.checked)}}
                      className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600
                      ${isAgreeValid ? '' : "border-red-600 bg-red-200"}`}
                    />
                  </div>
                  <div>
                    <p className="ml-2 block text-md text-gray-900">
                      By creating my account I confirm that I have read and agree to 
                        <a className='underline text-indigo-500 pl-1' href='legal/termsofuse' target="_blank">Terms</a> and   
                        <a className='underline text-indigo-500 pl-1' href="legal/privacypolicy" target="_blank">Privacy Policy</a>
                    </p>
                  </div>
                </div>
              </div>
              { !isAgreeValid && 
                    <p className="text-md font-medium text-red-600" id="email-error">Please agree to the terms and privacy policy.</p> }
                
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md h-12 text-lg bg-indigo-600 px-3 py-2 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {isLoadding ? <RotatingLines 
                                  strokeColor="white"
                                  strokeWidth="3"
                                  animationDuration="1.75"
                                  width="36"
                                  visible={true}/>
                              : 'Create my account' }
                </button>
              </div>
            </form>
            {/* <div className="flex mt-2 items-center justify-between">
                <div className="flex items-center">
                  <p className="ml-2 block text-mb text-gray-900">
                    By creating your account you agree to our
                      <a className='underline text-indigo-500 pl-1' href='#'>Terms</a> &   
                      <a className='underline text-indigo-500 pl-1' href="#">Privacy Policy</a>
                  </p>
                </div>
            </div>         */}
            {/* <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">Or continue with</span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-3 gap-3">
                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                  >
                    <span className="sr-only">Sign in with Facebook</span>
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                  >
                    <span className="sr-only">Sign in with Twitter</span>
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </div>

                <div>
                  <a
                    href="#"
                    className="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                  >
                    <span className="sr-only">Sign in with GitHub</span>
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <p className="mt-2 text-center text-xl  text-gray-900">
            Already have account? {' '}
            <Link to='/login' className="font-medium text-indigo-600 hover:text-indigo-500">
              Login here
            </Link>
          </p>
      </div>
    </>
  )
}