
import { useState, useCallback } from 'react'
import { HiArrowDown } from "react-icons/hi2";

import ActionButton from './v1ActionButton'
import OrderModal from './v1OrderModal'

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function HeroSection({}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    // Use useCallback to ensure the function reference stays stable
    const handleModalOpen = useCallback(() => {
      setIsModalOpen(true);
    }, []); // Empty dependency array since setIsModalOpen is stable

  return (
    
    // <div className="bg-indigo-600">
    <div className='bg-purple-950'>
      {/* <header className="absolute inset-x-0 top-0 z-50">
        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                className="h-8 w-auto"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header> */}
        <div className="mx-auto max-w-2xl lg:max-w-6xl pt-12 pb-32 sm:pt-12 lg:pt-12">
          <div className="sm:mb-8 sm:justify-center">
            <div className=" bg-slate-50 relative rounded-3xl px-8 py-6 text-md sm:text-xl leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            You're in! 🎉 {' '} Your welcome bonus is heading to your inbox right now. <span className='font-semibold'>But first </span>- don't miss out on the exclusive insider-only offer that helped 3,634 subscribers <span className='font-semibold'>get results 3.5x faster</span>.  
            <span className='text-red-600 font-semibold uppercase'> Warning: </span> <span className='font-semibold'>This special offer vanishes when you leave this page!</span>{' '}
              <a href='#read-now' className="font-bold text-indigo-600 inline-flex items-center">
                <span aria-hidden="true" className="absolute inset-0" />
                Read more <HiArrowDown className="ml-1 h-6 w-6"/>
              </a>


{/* You made it! 🎉 Your welcome bonus is on it's way to your email, but first - checkout this amazing story and one-time only offer below that's helped thousands get results they want 3.5x faster. 
Warning: This special deal won't be available again! */}

            </div>
          </div>

          <div className="relative isolate px-2 pt-14 lg:px-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          >
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
          </div>
          {/* updating buttom margins */}
          {/* <div className="mx-auto max-w-2xl lg:max-w-6xl pt-32 pb-32 sm:pt-48 lg:pt-56">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className=" bg-slate-50 relative rounded-full px-8 py-6 text-xl leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Thank you for joining Eat To Burn newsletter. Welcome email is on its way with your suprize bonus. DON'T CLOSE THIS PAGE..!
                Read this amazing story and one time ONLY offer as our thank you for joining 3,634 newsletter subscribers{' '}
                <a href="#" className="font-semibold text-indigo-600">
                  <span aria-hidden="true" className="absolute inset-0" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div> */}
            <h2 id="read-now" className="my-8 text-pretty text-xl font-medium text-gray-100 sm:text-2xl lg:text-3xl">The most incredible <b>FREE Gift</b> ever…</h2>
            <div className="text-center">
              <h1 className="text-balance text-4xl font-semibold tracking-tight text-yellow-300 sm:text-7xl">
              “So Apparently My Husband Has a New, Younger Girlfriend..!”
              </h1>
              <h2 className="my-6 text-pretty text-xl font-medium text-gray-100 sm:text-2xl lg:text-3xl">
              {/* <h2 className="mt-8 text-pretty text-4xl font-medium text-gray-900 sm:text-xl/8"> */}
                Now at last, you too can look legitimately 10 years younger… Lose all the weight you want without suffering! In fact you get to enjoy real food! There are no dangerous Big Pharma meds to take! You can melt midsection fat even if you’re convinced your metabolism is bad and you’re not able to lose weight. Enjoy abundant energy all day long and sound sleep all night! Best of all… you can learn to do this ABSOLUTELY FREE! But, only while spots are available! 
              </h2>

              <div className="mt-10 flex items-center justify-center gap-x-6">
                <ActionButton onOpenModal={handleModalOpen}/>
                {/* <a
                  href="#"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </a>
                <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                  Learn more <span aria-hidden="true">→</span>
                </a> */}
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          >
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
      </div>

      <OrderModal 
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          totalAmount="$10.65"
        />
    </div>
  )
}
