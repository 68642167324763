export default function ProgramHighlight() {
    return (
      <div className="bg-purple-800 mt-7">
        <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8 lg:py-16">
          <div className="mx-auto max-w-4xl text-center">
            
            {/* <p className="mt-3 text-2xl text-indigo-200 sm:mt-4">
            If you, too, can simply put your doubts aside for a short time, you can see yourself liberated from the prison of your old body… 
            </p> */}
            {/* <h2 className="text-2xl font-md tracking-wide text-white sm:text-4xl lg:my-4">
            You can gain more than just a life...           
            </h2>
            <h2 className="mx-auto text-2xl font-md tracking-wide text-white sm:text-4xl lg:my-4 lg:max-w-3xl">
           You can gain many years of amazingly happy and healthy life!            
            </h2> */}
            <h2 className="text-2xl font-md tracking-wide text-yellow-200 sm:text-4xl lg:my-4">
            Gain more than just a life... Gain many years of amazingly happy and healthy life!
            </h2>
            <h2 className="capitalize text-2xl font-bold tracking-tight text-white sm:text-4xl lg:my-4">
            Get free book while spots are still available            
            </h2>
          </div>
          {/* <dl className="mt-10 text-center sm:mx-auto sm:grid sm:max-w-3xl sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg/6 font-medium text-indigo-200">Pepperoni</dt>
              <dd className="order-1 text-5xl font-bold tracking-tight text-white">100%</dd>
            </div>
            <div className="mt-10 flex flex-col sm:mt-0">
              <dt className="order-2 mt-2 text-lg/6 font-medium text-indigo-200">Delivery</dt>
              <dd className="order-1 text-5xl font-bold tracking-tight text-white">24/7</dd>
            </div>
            <div className="mt-10 flex flex-col sm:mt-0">
              <dt className="order-2 mt-2 text-lg/6 font-medium text-indigo-200">Calories</dt>
              <dd className="order-1 text-5xl font-bold tracking-tight text-white">100k+</dd>
            </div>
          </dl> */}
        </div>
      </div>
    )
  }