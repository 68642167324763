export default function HighlightHeader() {
    return (
      <div className="mb-4 shadow-lg border-y-2 bg-yellow-50 p-3">
        <div className="flex">
          {/* <div className="shrink-0">
            <ExclamationTriangleIcon aria-hidden="true" className="size-5 text-yellow-400" />
          </div> */}
          <div className="mx-auto my-6 text-center">
            <h1 className="text-2xl md:text-3xl lg:text-4xl lg:max-w-7xl font-medium text-grey-800 ">
            This book contains every single one of the 
            <span className="font-bold"> get-thin-fast</span> keto shortcuts. You can learn all of these amazing secrets 
            <strong className='font-bold'> TODAY for FREE</strong>…
            </h1>
          </div>
        </div>
      </div>
    )
  }
  